
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getStudentGroups = createAsyncThunk("studentGroup/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/student-group?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const getStudentsForGroup = createAsyncThunk("studentGroup/get", async (data) => {
  
let response = await fetch(`${process.env.REACT_APP_URL}admin/api/student-group/students/${data.id}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addStudentGroup = createAsyncThunk("studentGroup/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/student-group/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const bulkUpdateGroup = createAsyncThunk("studentGroup/bulk-update", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/student-group/bulk-update`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const removeFromGroup = createAsyncThunk("studentGroup/remove-group", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/student-group/remove-from-group`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const updateStudentGroup = createAsyncThunk("studentGroup/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/student-group/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteStudentGroup = createAsyncThunk("studentGroup/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/student-group/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const studentGroupsSlice = createSlice({
  name: 'studentgroups',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudentGroups.fulfilled, (studentGroup, action) => {
     
        studentGroup.value.data.rows = action.payload.rows
        studentGroup.value.data.count = action.payload.count
        studentGroup.value.status = action.payload.message
        console.log('Updated studentGroup:', studentGroup.value.data.rows);

        
      })
      .addCase(getStudentGroups.pending, (studentGroup) => {
        studentGroup.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getStudentGroups.rejected, (studentGroup) => {
        studentGroup.status = "Failed to  fetch data..."
      })
      .addCase(updateStudentGroup.fulfilled, (studentGroup, action) => {
        console.log(action.payload.message, "action.payload.message")
        studentGroup.value.status = action.payload.message
        // find the index of the studentGroup with the updated id
        // const index = studentGroup.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the studentGroup at the found index with the updated studentGroup
        //   studentGroup.value.data[index] = action.payload;
        // }
      })
      .addCase(updateStudentGroup.pending, (studentGroup) => {
        studentGroup.status = "Updating studentGroup. Please wait a moment...";
      })
      .addCase(updateStudentGroup.rejected, (studentGroup) => {
        studentGroup.status = "Failed to update studentGroup...";
      })
      .addCase(deleteStudentGroup.fulfilled, (studentGroup, action) => {
        // // filter out the studentGroup with the deleted id
        // studentGroup.value.data = studentGroup.value.data.filter(item => item.id !== action.payload.id);
        studentGroup.value.status = action.payload.message
      })
      .addCase(deleteStudentGroup.pending, (studentGroup) => {
        studentGroup.status = "Deleting studentGroup. Please wait a moment...";
      })
      .addCase(deleteStudentGroup.rejected, (studentGroup) => {
        studentGroup.status = "Failed to delete studentGroup...";
      })
      .addCase(addStudentGroup.fulfilled, (studentGroup, action) => {
        // studentGroup.value.data.push(action.payload); // add the new studentGroup to the list
     })
     .addCase(addStudentGroup.pending, (studentGroup) => {
        studentGroup.status = "Adding studentGroup. Please wait a moment...";
     })
     .addCase(addStudentGroup.rejected, (studentGroup) => {
        studentGroup.status = "Failed to add studentGroup...";
     });
  }
})

export default studentGroupsSlice.reducer
