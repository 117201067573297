import React, { useState, useEffect } from 'react';
import Form from "../../../../components/form"
import { fetchSettings, updateSetting } from '../../../../redux/actions/admin/setting';
import { useSelector, useDispatch } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';

const SettingsPage = () => {
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    useEffect(() => {
        console.log("get'ed settings");
        dispatch(fetchSettings())


    }, [dispatch])

    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })


    const saveSettings = async (data) => {
        try {
            // return console.log(data, "DATA")
            const updateSettings = await dispatch(updateSetting({
                ...data,
                profile: data.profile,
                id: settings.id,
                resultHeader: data.resultHeader || null,
                showRemarks: data.showRemarks || false,
                showGrades: data.showGrades || false,
                showStatus: data.showStatus || false,
                subjectTitle: data.subjectTitle || "Subject Name"
            }));
            notifySuccess("Updated settings");
            dispatch(fetchSettings());
        } catch (err) {
            notifyError(err.message);
        }
    }


    const settings = useSelector((settings) => settings.setting.value);
    // console.log(settings);

    useEffect(() => {

        setFormData({
            ...settings,
            ...settings.examConfig
        })
    }, [settings])


    const fields = [
        {
            id: "resultHeader",
            name: "resultHeader",
            label: "Rank Card Header Image",
            prevImage: settings?.examConfig?.header,
            placeholder: "Click here to upload Your header's Image",
            type: "upload",
        },
        {
            id: "showRemarks",
            name: "showRemarks",
            label: "Show remarks column in rank card ?",
            desc: "Show Remarks ?",
            type: "switch",
        },
        {
            id: "showGrades",
            name: "showGrades",
            label: "Show grades column in rank card ?",
            desc: "Show Grades ?",
            type: "switch",
        },
        {
            id: "showStatus",
            name: "showStatus",
            label: "Show pass status column in rank card ?",
            desc: "Show Pass Status ?",
            type: "switch",
        },
        {
            id: "subjectTitle",
            name: "subjectTitle",
            label: "Set Subject Title For Rank Card",
            placeHolder: "Subject Title",
            type: "dropdown",
            list: [
                { id: "Subject Name", name: "Subject Name" },
                { id: "Subject Code", name: "Subject Code" },
            ],
            toSelect: "id",
            selectedvalue: "subjectTitle",
            required: true,
        },
    ];

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                <Form
                    fields={fields}
                    formData={formData}
                    onChange={(newFormData) => setFormData(newFormData)}
                />
                <div className='flex justify-end'>
                    <button type="button"
                        onClick={() => saveSettings(formData)}
                        className="w-1/6 border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70"> Save </button>
                </div>
            </div>

        </div>
    );
};

export default SettingsPage;


