import { React, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addHallCheckLog, updateHallCheckLog, getHallCheckLogs } from '../../../../redux/actions/admin/hallcheck';
import { getHalls, getStudentsForHall } from '../../../../redux/actions/admin/hall';
import { getStudents } from '../../../../redux/actions/admin/student';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import toast from 'react-hot-toast';
import DropdownSelect from '../../../../components/select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const notifySuccess = (message) => toast.success(message, {
    style: {
        padding: '35px',
        color: '#a0ca00',
    },
    duration: 3000,
    iconTheme: {
        primary: '#a0ca00',
        secondary: '#222c25',
    }
});

const notifyError = (message) => toast.error(message, {
    style: {
        padding: '35px',
        color: '#ff4d4d',
    },
    duration: 3000,
    iconTheme: {
        primary: '#ff4d4d',
        secondary: '#222c25',
    }
});

function HallCheckForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [mode, setMode] = useState('add');
    const [hall, setHall] = useState(null);
    const [formData, setFormData] = useState({
        date: new Date(),
        hallId: '',
    });
    const [existingHallCheckLogs, setExistingHallCheckLogs] = useState({});
    const [studentStatuses, setStudentStatuses] = useState({});
    const [students, setStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const halls = useSelector((state) => state?.hall?.value?.data);

    useEffect(() => {
        const initializeForm = async () => {
            setIsLoading(true);
            setMode(id === 'add' ? 'add' : 'edit');

            try {
                await dispatch(getHalls({ limit: 100 }));
                await dispatch(getStudents({ limit: 1000 }));

                if (id !== 'add') {
                    const response = await dispatch(getHallCheckLogs({ id }));
                    const hallData = response.payload.rows[0];
                    
                    if (hallData) {
                        setFormData({
                            date: new Date(hallData.hallchecks[0].hallchecklogs[0]?.timeChecked || new Date()),
                            hallId: hallData.id
                        });

                        const studentsData = await dispatch(getStudentsForHall({ id: hallData.id }));
                        setStudents(studentsData.payload);

                        // Create a map of existing hallchecklogs for each hallcheck
                        const hallCheckLogsMap = {};
                        hallData.hallchecks.forEach(hallcheck => {
                            if (hallcheck.hallchecklogs && hallcheck.hallchecklogs[0]) {
                                hallCheckLogsMap[hallcheck.id] = hallcheck.hallchecklogs[0];
                            }
                        });
                        setExistingHallCheckLogs(hallCheckLogsMap);

                        // Initialize student statuses based on hallchecklogdata
                        const existingStatuses = {};
                        studentsData.payload.forEach(student => {
                            existingStatuses[student.id] = hallData.hallchecks.map(hallcheck => {
                                const hallCheckLog = hallcheck.hallchecklogs[0];
                                const studentLogData = hallCheckLog?.hallchecklogdata?.find(
                                    logData => logData.studentId === student.id
                                );

                                return {
                                    hallcheckId: hallcheck.id,
                                    hallchecklogId: hallCheckLog?.id,
                                    status: studentLogData?.status || 'PENDING',
                                    hallchecklogdataId: studentLogData?.id ,
                                    remarks: `Check #${hallData.hallchecks.indexOf(hallcheck) + 1}`
                                };
                            });
                        });

                        setStudentStatuses(existingStatuses);
                        setHall(hallData);
                    }
                }
            } catch (error) {
                console.error('Error initializing form:', error);
                notifyError('Failed to load form data');
            }
            
            setIsLoading(false);
        };

        initializeForm();
    }, [id, dispatch]);

    useEffect(() => {
        console.log(studentStatuses, "studentStatuses")
    }, [studentStatuses]);

    useEffect(() => {
        if (mode === 'add' && formData.hallId) {
            const selectedHall = halls?.rows?.find(hall => hall.id === formData.hallId);
            setHall(selectedHall);
            dispatch(getStudentsForHall({ id: formData.hallId })).then((data) => {
                setStudents(data.payload);

                const initialStatuses = {};
                data.payload.forEach(student => {
                    initialStatuses[student.id] = selectedHall?.hallchecks?.map((hc, index) => ({
                        hallcheckId: hc.id,
                        hallchecklogId: null,
                        status: 'PENDING',
                        remarks: `Check #${index + 1}`
                    })) || [];
                });
                setStudentStatuses(initialStatuses);
            });
        }
    }, [formData.hallId, halls, mode]);

    const handleDateChange = (date) => {
        setFormData(prev => ({ ...prev, date }));
    };

    const handleHallChange = (hallId) => {
        setFormData(prev => ({ ...prev, hallId }));
    };

    const toggleStudentStatus = (studentId, hallcheckId) => {
        setStudentStatuses(prev => {
            const studentCheckStatuses = [...prev[studentId]];
            const hallcheckIndex = studentCheckStatuses.findIndex(
                status => status.hallcheckId === hallcheckId
            );

            if (hallcheckIndex !== -1) {
                const currentStatus = studentCheckStatuses[hallcheckIndex].status;
                studentCheckStatuses[hallcheckIndex].status =
                    currentStatus === 'PRESENT' ? 'ABSENT' : 'PRESENT';
            }

            return {
                ...prev,
                [studentId]: studentCheckStatuses
            };
        });
    };

    const handleSubmit = () => {
        // Structure the data to match the API's expected format
        const hallCheckData = {
            ...formData,
            date: moment(formData.date).format('YYYY-MM-DD'),
            hallcheckData: hall.hallchecks.map(hallcheck => {
                // Get existing hallchecklog if it exists
                const existingLog = existingHallCheckLogs[hallcheck.id];
                
                // Get all status entries for this hallcheck
                const statusEntries = [];
                Object.entries(studentStatuses).forEach(([studentId, checks]) => {
                    const checkStatus = checks.find(c => c.hallcheckId === hallcheck.id);
                    if (checkStatus) {
                        statusEntries.push({
                            studentId,
                            status: checkStatus.status,
                            hallchecklogdataId: checkStatus?.hallchecklogdataId,
                            remarks: checkStatus.remarks,
                            hallchecklogId: existingLog?.id
                        });
                    }
                });

                return {
                    hallcheckId: hallcheck.id,
                    hallchecklogId: existingLog?.id,
                    timeChecked: formData.date,
                    statusData: statusEntries
                };
            })
        };

        const action = mode === 'add' 
            ? dispatch(addHallCheckLog(hallCheckData))
            : dispatch(updateHallCheckLog({ id, ...hallCheckData }));

            notifySuccess(`Hall Check ${mode === 'add' ? 'Created' : 'Updated'}`);
            navigate('/admin/hostel/hostel-attendance');
        action
            .then(() => {
            })
            .catch((error) => {
                console.error(`Failed to ${mode} hall check:`, error);
                notifyError(`Failed to ${mode} hall check`);
            });
    };

    if (isLoading) {
        return <div className="flex justify-center items-center min-h-screen">
            <div className="text-xl text-gray-600">Loading...</div>
        </div>;
    }

    return (
        <div className='bg-lightPrimary min-h-screen p-4'>
            <div className='p-2 bg-white rounded-xl shadow-lg'>
                <p className='text-center text-2xl font-bold my-4 text-gray-800'>
                    {mode === 'add' ? 'Create New Hall Check' : 'Edit Hall Check'}
                </p>

                <div className="grid grid-cols-1 gap-4 mb-6">
                    <div className="flex flex-col gap-4 px-6">
                        <div className="flex flex-col">
                            <label className="text-sm font-medium text-gray-700 mb-2">Date</label>
                            <DatePicker
                                selected={formData.date}
                                onChange={handleDateChange}
                                dateFormat="MMMM d, yyyy"
                                className="border-gray-300 rounded-lg focus:border-brand-500 focus:ring-brand-500 block w-full sm:text-sm p-3 shadow-sm"
                                maxDate={new Date()}
                                placeholderText="Select date"
                            />
                        </div>

                        <div className="flex flex-col">
                            <label className="text-sm font-medium text-gray-700 mb-2">Halls </label>
                            <DropdownSelect
                                list={halls?.rows?.map(hall => ({
                                    name: hall.name,
                                    id: hall.id
                                })) || []}
                                placeHolder={ mode == "edit" ? hall?.name : "Select Hall"}
                                toSelect="id"
                                onChange={handleHallChange}
                                value={formData.hallId}
                                disabled={mode === 'edit'}
                            />
                        </div>
                    </div>
                </div>

                {hall && hall.hallchecks && (
                    <div className="flex border-t pt-4 px-6">
                        <div className="w-1/4 pr-4 border-r">
                            <h3 className="font-bold text-lg mb-4 text-gray-700 pl-2">Students</h3>
                            <div className="max-h-[600px] overflow-y-auto">
                                {students?.map(student => (
                                    <div
                                        key={student.id}
                                        className="mb-2 p-2 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
                                    >
                                        {student.admission.name || `Student ${student.id.slice(0, 8)}`}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="flex-grow grid grid-cols-3 gap-4 ml-4">
                            {hall.hallchecks.map((hallcheck, index) => (
                                <div key={hallcheck.id} className="border rounded-lg shadow-md p-2 bg-white">
                                    <h4 className="font-semibold text-center mb-3 text-gray-700">
                                        Check {index + 1}
                                    </h4>
                                    <div className="max-h-[600px] overflow-y-auto">
                                        {students?.map(student => (
                                            <div
                                                key={student.id}
                                                className={`mb-2 p-2 rounded-md cursor-pointer text-center transition-colors 
                                                    ${studentStatuses[student.id]?.find(
                                                    status => status.hallcheckId === hallcheck.id
                                                )?.status === 'PRESENT'
                                                        ? 'bg-green-100 hover:bg-green-200 text-green-800'
                                                        : studentStatuses[student.id]?.find(
                                                            status => status.hallcheckId === hallcheck.id
                                                        )?.status === 'ABSENT'
                                                            ? 'bg-red-100 hover:bg-red-200 text-red-800'
                                                            : 'bg-gray-100 hover:bg-gray-200 text-gray-800'}`}
                                                onClick={() => toggleStudentStatus(student.id, hallcheck.id)}
                                            >
                                                {studentStatuses[student.id]?.find(
                                                    status => status.hallcheckId === hallcheck.id
                                                )?.status || 'PENDING'}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <button
                    onClick={handleSubmit}
                    disabled={!formData.date || !formData.hallId}
                    className={`mt-6 mb-4 w-full border-2 text-white linear rounded-xl ${!formData.date || !formData.hallId
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-brand-700 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70'
                        } px-4 py-3 text-center text-base font-medium transition duration-200 shadow-md`}
                >
                    {mode === 'add' ? 'Create Hall Check' : 'Update Hall Check'}
                </button>
            </div>
        </div>
    );
}

export default HallCheckForm;