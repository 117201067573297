
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getTimetables = createAsyncThunk("timetable/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/timetable?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addTimetable = createAsyncThunk("timetable/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/timetable/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateTimetable = createAsyncThunk("timetable/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/timetable/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteTimetable = createAsyncThunk("timetable/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/timetable/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const timetablesSlice = createSlice({
  name: 'timetablees',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTimetables.fulfilled, (timetable, action) => {
     
        timetable.value.data.rows = action.payload.rows
        timetable.value.data.count = action.payload.count
        timetable.value.status = action.payload.message
        console.log('Updated timetable:', timetable.value.data.rows);

        
      })
      .addCase(getTimetables.pending, (timetable) => {
        timetable.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getTimetables.rejected, (timetable) => {
        timetable.status = "Failed to  fetch data..."
      })
      .addCase(updateTimetable.fulfilled, (timetable, action) => {
        console.log(action.payload.message, "action.payload.message")
        timetable.value.status = action.payload.message
        // find the index of the timetable with the updated id
        // const index = timetable.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the timetable at the found index with the updated timetable
        //   timetable.value.data[index] = action.payload;
        // }
      })
      .addCase(updateTimetable.pending, (timetable) => {
        timetable.status = "Updating timetable. Please wait a moment...";
      })
      .addCase(updateTimetable.rejected, (timetable) => {
        timetable.status = "Failed to update timetable...";
      })
      .addCase(deleteTimetable.fulfilled, (timetable, action) => {
        // // filter out the timetable with the deleted id
        // timetable.value.data = timetable.value.data.filter(item => item.id !== action.payload.id);
        timetable.value.status = action.payload.message
      })
      .addCase(deleteTimetable.pending, (timetable) => {
        timetable.status = "Deleting timetable. Please wait a moment...";
      })
      .addCase(deleteTimetable.rejected, (timetable) => {
        timetable.status = "Failed to delete timetable...";
      })
      .addCase(addTimetable.fulfilled, (timetable, action) => {
        // timetable.value.data.push(action.payload); // add the new timetable to the list
     })
     .addCase(addTimetable.pending, (timetable) => {
        timetable.status = "Adding timetable. Please wait a moment...";
     })
     .addCase(addTimetable.rejected, (timetable) => {
        timetable.status = "Failed to add timetable...";
     });
  }
})

export default timetablesSlice.reducer
