import React from 'react';
import * as Select from '@radix-ui/react-select';
import { styled } from '@stitches/react';
import { violet, mauve, blackA } from '@radix-ui/colors';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
// let styles = {}
const DropdownSelect = (props) => {
    const [value, setValue] = React.useState('');
    let { label, placeHolder, name, list, onChange, toSelect, triggerStyles, listStyles, selectedvalue, toShow, selectedObject } = props
    console.log(selectedObject, "LISTS")
    // console.log()
    // getValueByKeyArray(selectedObject, toShow)
    function getValueByKeyArray(obj, keysArray) {
        console.log(name, obj, keysArray, "   obj, keysArray")
        if(!obj || !keysArray) return placeHolder
        let value = obj;
        for (const key of keysArray) {
          if (value.hasOwnProperty(key)) {
            value = value[key];
          } else {
            return undefined; // Key not found
          }
        }
        return value;
      }

    if (!props.list) return null
    // let selectedOption = list.find(obj => obj.id === selectedvalue);
    // styles = props.styles
    return (
        <Select.Root value={value} onValueChange={(newValue) => {
            setValue(newValue);
            onChange(newValue);
        }}>
                {name  && 
                <label
                className="text-sm font-bold text-navy-700 dark:text-white"
            >
                {label}
            </label>
            }
            <SelectTrigger className={`rounded-xl !shadow-none !border-grey-500 flex items-center justify-between !py-6 ${triggerStyles}`}  aria-label={label}>
                <Select.Value className={`!text- ${listStyles} text-red`} placeholder={toShow? getValueByKeyArray(selectedObject, toShow) : selectedvalue || placeHolder} />
                <SelectIcon>
                    <ChevronDownIcon />
                </SelectIcon>
            </SelectTrigger>
            <Select.Portal>
                <SelectContent>
                    <SelectScrollUpButton>
                        <ChevronUpIcon />
                    </SelectScrollUpButton>
                    <SelectViewport>
                        <Select.Group>
                            <SelectLabel>{name}</SelectLabel>
                            {list.map((subData) => (
                                <SelectItem key={subData.id} value={subData[toSelect]}>
                                    {toShow? getValueByKeyArray(subData, toShow) : subData.name}
                                </SelectItem>
                            ))}
                        </Select.Group>
                        <SelectSeparator />
                    </SelectViewport>
                    <SelectScrollDownButton>
                        <ChevronDownIcon />
                    </SelectScrollDownButton>
                </SelectContent>
            </Select.Portal>
        </Select.Root>
    );
};


const SelectTrigger = styled(Select.SelectTrigger, {
    // all: 'unset',
    width: "100%",
    margin: "8px 0px",
    // display: 'inline-flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    borderRadius: 12,
    padding: '8px 15px',
    fontSize: 13,
    lineHeight: 1,
    height: 35,
    gap: 5,
    // backgroundColor: `${styles.bgColor ? styles.bgColor : 'white'}`,
    color: "black",
    border: "1px solid lightgrey",
    boxShadow: `0 2px 10px ${blackA.blackA4}`,
    '&:hover': { backgroundColor: mauve.mauve3 },
    '&:focus': { boxShadow: `0 0 0 2px black` },
    '&[data-placeholder]': { color: "Black" },
});

const SelectIcon = styled(Select.SelectIcon, {
    color: violet.violet11,
});

const SelectContent = styled(Select.Content, {
    overflow: 'hidden',
    zIndex: 999,
    backgroundColor: 'white',
    borderRadius: 6,
    boxShadow:
        '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
});

const SelectViewport = styled(Select.Viewport, {
    padding: 5,
});

const SelectItem = React.forwardRef(({ children, ...props }, forwardedRef) => {
    return (
        <StyledItem {...props} ref={forwardedRef}>
            <Select.ItemText className='!p-3'>{children}</Select.ItemText>
            <StyledItemIndicator>
                <CheckIcon />
            </StyledItemIndicator>
        </StyledItem>
    );
});

const StyledItem = styled(Select.Item, {
    fontSize: 13,
    lineHeight: 1,
    color: "black",
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    height: 25,
    padding: '0 35px 0 25px',
    position: 'relative',
    userSelect: 'none',

    '&[data-disabled]': {
        color: mauve.mauve8,
        pointerEvents: 'none',
    },

    '&[data-highlighted]': {
        outline: 'none',
        backgroundColor: "#a0ca00",
        color: violet.violet1,
    },
});

const SelectLabel = styled(Select.Label, {
    padding: '0 25px',
    fontSize: 12,
    lineHeight: '25px',
    color: mauve.mauve11,
});

const SelectSeparator = styled(Select.Separator, {
    height: 1,
    backgroundColor: violet.violet6,
    margin: 5,
});

const StyledItemIndicator = styled(Select.ItemIndicator, {
    position: 'absolute',
    left: 0,
    width: 25,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const scrollButtonStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 25,
    backgroundColor: 'white',
    color: violet.violet11,
    cursor: 'default',
};

const SelectScrollUpButton = styled(Select.ScrollUpButton, scrollButtonStyles);

const SelectScrollDownButton = styled(Select.ScrollDownButton, scrollButtonStyles);

export default DropdownSelect;