import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUsers, addUser, updateUser, deleteUser, resendPasswordEmail, resetPassword } from '../../../redux/actions/admin/user';
import { getRoles } from '../../../redux/actions/admin/role';
import { getClasses } from '../../../redux/actions/admin/classRoom';
import toast, { Toaster } from 'react-hot-toast';
import DropdownSelect from 'components/select';
import Paginate from 'components/paginate';
import moment from 'moment';

import {
  FiTrendingUp,
  FiX,
  FiLayers,
  FiSearch,
  FiUnlock,
  FiEdit2,
  FiTrash,
  FiSettings,
  FiSend,
  FiRefreshCw,
  FiChevronDown
} from "react-icons/fi";

import Widget from "components/widget/Widget";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/shadcn/table";

import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";

export const User = (props) => {
  const [formData, setFormData] = useState({});
  const [passwordFormData, setPasswordFormData] = useState({});
  // const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    name: "",
    status: "All",
    limit: 50,
    page: 1
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [isResetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const [resetUser, setResetUser] = useState({})
  const notifySuccess = (message) => toast.success(message, {
    style: {
      padding: '35px',
      color: '#a0ca00',
    },
    duration: 3000,
    iconTheme: {
      primary: '#a0ca00',
      secondary: '#222c25',
    }
  })
  const notifyError = (message) => toast.error(message, {
    style: {
      border: '1px solid #fff',
      padding: '35px',
      color: 'red',
    },
    iconTheme: {
      primary: 'red',
      secondary: '#fff',
    }
  })

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers(filter))
    dispatch(getClasses({ limit: 100 }))

    // setFormData({})
  }, [dispatch])

  useEffect(() => {
    dispatch(getRoles({}));
    // setFormData({})
  }, [])

  const users = useSelector((users) => users.user.value.data);
  const classes = useSelector((classes) => classes.classRoom.value.data);
  const roles = useSelector((roles) => roles.role.value.data);
  const settings = useSelector((settings) => settings.setting.value);

  const [selectedRoleValue, setSelectedRoleValue] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [code, setCode] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedClassValue, setSelectedClassValue] = useState([]);

  const handleActionButtonClick = (userId, action) => {
    if (action === 'edit') setIsDialogOpen(true);
    if (action === 'delete') setIsDeleteDialogOpen(true);
    if (action === 'reset-password') {
      setResetPasswordDialogOpen(true);
      setResetUser(userId)
    }
    if (userId == null) {
      setIsDialogOpen(true)
      return setFormData({})
    }

    const selectedUser = users.rows.find((user) => user.id === userId);
    console.log(selectedUser, "FORM")
    setSelectedRoleValue(selectedUser.role ? selectedUser.role.id : null)

    setFormData(selectedUser)
  };

  useEffect(() => {
    dispatch(getUsers(filter));
  }, [filter]);

  const handleCrudUser = async () => {
    try {
      setIsDialogOpen(false)
      if (formData.id) await dispatch(updateUser(formData));
      if (!formData.id) await dispatch(addUser(formData));
      notifySuccess("Success")

      setFormData({});
      await dispatch(getUsers(filter));

    } catch (error) {
      console.error('Failed to add state:', error);
    }
  };

  const handleDelete = async () => {
    try {
      setIsDeleteDialogOpen(false)
      await dispatch(deleteUser(formData.id));
      // setFormData({});
      notifySuccess("Deleted Successfully");
      await dispatch(getUsers(filter));

    } catch (error) {
      console.error('Failed to add state:', error);
    }
  };

  const handlePasswordChange = async () => {
    try {
      setIsDeleteDialogOpen(false)
      await dispatch(resetPassword({ ...resetUser, ...passwordFormData }));
      // setFormData({});
      notifySuccess("Password Reset Successfull");
    } catch (error) {
      console.error('Failed to add state:', error);
    }
  };

  const sendResetPasswordEmail = async (userId, user) => {
    try {
      setSelectedUser(user)
      await dispatch(resendPasswordEmail(userId)).then((response) => {
        // console.log(response.payload.password, "NEW PASSWORD")
        setPassword(response.payload.password)
        setCode(response.payload.code)
        setIsPasswordDialogOpen(true)
        // notifySuccess("Password Reset Email Sent");
      });
      notifySuccess("Password Reset Successfull");
    } catch (error) {
      console.error('Failed to add state:', error);
    }
  };

  // const users = [];
  const fields = [
    // {
    //   id: "username",
    //   name: "username",
    //   label: "User Name",
    //   placeholder: "Enter User's Name",
    //   type: "text",
    //   // reflectChange: false
    // },
    {
      id: "Status",
      name: "status",
      label: "Status",
      desc: "Set status as active/inactive",
      type: "switch",
    },
    {
      id: "fullname",
      name: "fullname",
      label: "Full Name",
      placeholder: "Enter User's Full Name",
      type: "text",
      // reflectChange: false
    },
    {
      id: "email",
      name: "email",
      label: "Email",
      placeholder: "Enter User's Email",
      type: "mail",
      // reflectChange: false
    },
    {
      id: "mobile",
      name: "mobile",
      label: "User Mobile",
      placeholder: "Enter User's Mobile",
      type: "number",
    },
    {
      id: "profile",
      name: "profile",
      label: "Profile Image",
      prevImage: "user.profile",
      placeholder: "Click here to upload Your Profile Image",
      type: "upload",
    },
    {
      id: "roles",
      name: "roles",
      label: "User Role",
      desc: "Set a user role",
      type: "dropdown",
      toSelect: "id",
      selectedvalue: "role",
      placeHolder: "select role",
      list: roles.rows
    },
    {
      id: "allowCrossAccess",
      name: "allowCrossAccess",
      label: "Allow Multi Access",
      desc: "Set status as active/inactive",
      type: "switch",
    }
  ];

  const passwordFields = [
    {
      id: "password",
      name: "password",
      label: "New Password",
      placeholder: "Enter a new password",
      type: "password",
      // reflectChange: false
    },
    {
      id: "confirm-password",
      name: "confirm-password",
      label: "New Password",
      placeholder: "Confirm password",
      type: "password",
      // reflectChange: false
    },
  ];

  const handleSelectUser = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  }


  const sendWelcomeMessage = async (selectedUsers) => {
    const totalCount = selectedUsers.length;
    let successCount = 0;
    let failedCount = 0;
    selectedUsers.map(async (userId) => {
      try {
        await dispatch(resendPasswordEmail(userId)).then((response) => {
          successCount++;
        });
      } catch (error) {
        failedCount++;
        console.error('Failed to add state:', error);
      }
      // notifySuccess(`Welcome message sent.\n" + successCount + " users. Failed to send to " + failedCount + " users.");
    })
    setSelectedUsers([]);
    // notifySuccess(`Welcome message sent.\n\nSuccess Count: ${successCount}\nFailed Count: ${failedCount}`);
    notifySuccess(`Sending Welcome Message to ${totalCount} users.`);
  }

  return (
    <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4">
      <Toaster />
      <div className="h-full w-full rounded-xl">
        {/* <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
          <Widget
            icon={<FiLayers className="h-7 w-7" />}
            title={"Total Users"}
            subtitle={users.count || 0}
            styling={
              {
                iconContStyles: "bg-brand-500",
                iconStyles: "text-white",
                border: "border-solid border-2 border-yellow-100"
              }
            }
          />
          <Widget
            icon={<FiTrendingUp className="h-7 w-7" />}
            title={"Active Users"}
            subtitle={users.activeCount || 0}
            styling={
              {
                iconContStyles: "bg-green-500",
                iconStyles: "text-white",
                border: "border-solid border-2 border-green-100"
              }
            }
          />
          <Widget
            icon={<FiX className="h-7 w-7" />}
            title={"Inactive Users"}
            subtitle={users.inactiveCount || 0}
            styling={
              {
                iconContStyles: "bg-red-500",
                iconStyles: "text-white",
                border: "border-solid border-2 border-red-100"
              }
            }
          />


        </div> */}

        <div className='mt-3  p-4 rounded-xl border-solid border-2 border-gray-100'>
        <Table className="border-b border-grey-500">
        <TableCaption className="mb-6 font-bold text-2xl text-start text-black">
          <div className="flex justify-between items-center">
            <div className="flex space-x-6 w-1/2 items-center">
              <div className="p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white">
                <FiSearch className="h-5 w-5 text-gray-400 dark:text-white mr-2" />
                <input
                  onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                  type="text"
                  placeholder="Search..."
                  className="block h-full w-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                />
              </div>
              {/* Your existing DropdownSelect component */}
            </div>
            <div className="flex space-x-4">
              {selectedUsers.length > 0 && (
                <button className="text-white linear rounded-full bg-yellow-500 px-6 py-3 text-base font-medium transition duration-200 hover:bg-white hover:text-yellow-500 hover:border-2 hover:border-yellow-500">
                  Send Welcome Message
                </button>
              )}
              <button
                onClick={() => handleActionButtonClick(null, "add")}
                className="text-white linear rounded-full bg-brand-700 px-6 py-3 text-base font-medium transition duration-200 hover:bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500"
              >
                Add User
              </button>
            </div>
          </div>
        </TableCaption>

        <TableHeader>
          {selectedUsers.length > 0 && (
            <div className="py-2 px-4 mb-4 bg-gray-50 rounded-lg">
              <span className="font-medium">Selected Users: {selectedUsers.length}</span>
            </div>
          )}
          <TableRow className="bg-gray-50">
            <TableHead className="w-[5%] py-4">
              <input
                type="checkbox"
                checked={users.rows.length > 0 && selectedUsers.length === users.rows.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedUsers(users.rows.map((user) => user.id));
                  } else {
                    setSelectedUsers([]);
                  }
                }}
                className="h-4 w-4"
              />
            </TableHead>
            <TableHead className="w-[8%]">S. no.</TableHead>
            <TableHead className="w-[40%]">User Information</TableHead>
            <TableHead className="w-[15%]">Role</TableHead>
            <TableHead className="w-[20%]">Status</TableHead>
            <TableHead className="w-[12%] text-center">Actions</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {users.rows.length > 0 &&
            users.rows.map((user, index) => (
              <TableRow key={user.id} className="hover:bg-gray-50">
                <TableCell className="py-4">
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(user.id)}
                    onChange={() => handleSelectUser(user.id)}
                    className="h-4 w-4"
                  />
                </TableCell>
                <TableCell>{(filter.page - 1) * filter.limit + index + 1}</TableCell>
                <TableCell>
                  <div className="flex items-center space-x-4">
                    <img
                      className="h-16 w-16 rounded-full object-cover"
                      src={user.profile ? process.env.REACT_APP_URL + "images" + user.profile : "https://shorturl.at/insF3"}
                      alt={user.fullname}
                    />
                    <div className="space-y-1">
                      <h4 className="text-md font-semibold text-brand-500">{user.fullname}</h4>
                      <p className="text-sm text-gray-600">{user.email}</p>
                      <p className="text-sm text-gray-600">{user.mobile}</p>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <span className="inline-block px-3 py-1 rounded-full bg-gray-100 text-gray-700">
                    {user.role ? user.role.name : "-"}
                  </span>
                </TableCell>
                <TableCell>
                  <div className="space-y-2">
                    <div className="text-sm text-gray-600">
                      Last Used: {user.lastUsed ? moment(user.lastUsed).format("DD-MM-YYYY hh:mm A") : "-"}
                    </div>
                    <div className="text-sm text-gray-600">
                      Last Login: {user.lastLogin ? moment(user.lastLogin).format("DD-MM-YYYY hh:mm A") : "-"}
                    </div>
                    {user.status ? (
                      <div className="flex items-center space-x-2">
                        <div className="bg-green-500 rounded-full p-1">
                          <FiTrendingUp className="h-4 w-4 text-white" />
                        </div>
                        <span className="text-sm text-green-600">Active</span>
                      </div>
                    ) : (
                      <div className="flex items-center space-x-2">
                        <div className="bg-red-500 rounded-full p-1">
                          <FiX className="h-4 w-4 text-white" />
                        </div>
                        <span className="text-sm text-red-600">Inactive</span>
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex justify-center space-x-2">
                    <button
                      onClick={() => handleActionButtonClick(user.id, "edit")}
                      className="p-1 w-fit rounded-full bg-yellow-500 hover:bg-yellow-600 transition-colors"
                      title="Update User"
                    >
                      <FiEdit2 className="h-4 w-4 text-white" />
                    </button>
                    <button
                      onClick={() => sendResetPasswordEmail(user.id, user)}
                      className="p-1 w-fit rounded-full bg-blue-500 hover:bg-blue-600 transition-colors"
                      title="Reset Password"
                    >
                      <FiSend className="h-4 w-4 text-white" />
                    </button>
                    <button
                      onClick={() => handleActionButtonClick(user.id, "delete")}
                      className="p-1 w-fit rounded-full bg-red-500 hover:bg-red-600 transition-colors"
                      title="Delete"
                    >
                      <FiTrash className="h-4 w-4 text-white" />
                    </button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>


          {users.rows.length > 0 &&
            <Paginate
              page={filter.page}
              totalCount={users.count}
              limit={filter.limit}
              changePage={(value) => {
                setFilter({ ...filter, ["page"]: value })
              }}
            />
          }

          {users.rows.length == 0 &&
            <div className='p-10 flex flex-col justify-center items-center'>
              <h4 className='mb-5 text-2xl text-brand-500 font-medium'> No Users Added! Click below to add one. </h4>
              <button
                onClick={() => handleActionButtonClick(null, "add")}
                className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                Add User
              </button>
            </div>
          }

          <DialogCustom

            open={isDialogOpen}
            onOpenChange={setIsDialogOpen}
            dialogTitle="Edit User"
            dialogWidth="w-1/2"
            dialogDesc="Update the neccessary fields and save."
            content={
              <>
                <Form
                  fields={fields}
                  formData={formData}
                  onChange={(newFormData) => setFormData(newFormData)}
                />
                <div className='flex justify-end'>
                  <button
                    onClick={handleCrudUser}
                    className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70">
                    {formData.id ? "Update" : "Add" + " User"}
                  </button>
                </div></>
            }
          />
          <DialogCustom

            open={isDeleteDialogOpen}
            onOpenChange={setIsDeleteDialogOpen}
            dialogTitle="Delete User"
            dialogWidth="w-1/2"
            dialogDesc="Are you sure you want to delete this user ?"
            content={
              <div className='flex flex-row-reverse'>
                <button
                  onClick={handleDelete}
                  className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                  Delete
                </button>
                <button
                  onClick={() => {
                    setIsDeleteDialogOpen(false);
                  }}
                  className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                  Cancel
                </button>
              </div>
            }
          />
          <DialogCustom
            open={isPasswordDialogOpen}
            onOpenChange={setIsPasswordDialogOpen}
            dialogTitle="New Password"
            dialogWidth="w-1/2"
            dialogDesc="New password has been sent to the user's WhatsApp."
            content={
              <>
                <div className="mb-5 flex justify-center items-center border-2 p-4 rounded-lg">
                  <p
                    className="text-xl font-medium whitespace-pre-line"
                    dangerouslySetInnerHTML={{
                      __html: `Assalamu alaikum ${selectedUser?.fullname},<br/>
            Please use the below details to log in to the ${settings?.name} app:<br/>
            Makthab Code: ${code}<br/>
            Mobile Number: ${selectedUser?.mobile}<br/>
            Password: ${password}<br/>
            Download the app from <a href="https://app.maktab.info/app" target="_blank" class="text-brand-500 underline">https://app.maktab.info/app</a><br/>
            For any queries, feel free to contact ${settings?.adminMobile}.`,
                    }}
                  ></p>
                </div>
                <div className="flex flex-row-reverse">
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `Assalamu alaikum ${selectedUser?.fullname},\n\nPlease use the below details to log in to the ${settings?.name} app:\n\nMakthab Code: ${code}\nMobile Number: ${selectedUser?.mobile}\nPassword: ${password}\n\nDownload the app from https://app.maktab.info/app.\nFor any queries, feel free to contact ${settings?.adminMobile}.`
                      );
                      notifySuccess("Password Copied to Clipboard");
                    }}
                    className="text-white linear rounded-xl border-2 border-white bg-brand-500 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg"
                  >
                    Copy
                  </button>
                  <button
                    onClick={() => {
                      setIsDeleteDialogOpen(false);
                    }}
                    className="mr-4 text-white linear rounded-xl border-2 border-white bg-yellow-500 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg"
                  >
                    Close
                  </button>
                </div>
              </>
            }
          />

          <DialogCustom

            open={isResetPasswordDialogOpen}
            onOpenChange={setResetPasswordDialogOpen}
            dialogTitle={`Reset password for ${resetUser.username}`}
            dialogWidth="w-1/2"
            dialogDesc="Enter a new password and confirm"
            content={
              <>
                <Form
                  fields={passwordFields}
                  formData={passwordFormData}
                  onChange={(newFormData) => setPasswordFormData(newFormData)}
                />
                <div className='flex justify-end'>
                  <button
                    onClick={() => setResetPasswordDialogOpen(false)}
                    className="text-black linear rounded-xl bg-red-500 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70 mr-5">
                    Cancel
                  </button>
                  <button
                    onClick={handlePasswordChange}
                    className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70">
                    Reset Password
                  </button>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  )
};

export default User