
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getHallCheckLogs = createAsyncThunk("hallcheck/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/hallcheck?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addHallCheckLog = createAsyncThunk("hallcheck/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/hallcheck/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateHallCheckLog = createAsyncThunk("hallcheck/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/hallcheck/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteHallCheckLog = createAsyncThunk("hallcheck/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/hallcheck/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const hallchecksSlice = createSlice({
  name: 'hallchecks',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHallCheckLogs.fulfilled, (hallcheck, action) => {
     
        hallcheck.value.data.rows = action.payload.rows
        hallcheck.value.data.count = action.payload.count
        hallcheck.value.status = action.payload.message
        console.log('Updated hallcheck:', hallcheck.value.data.rows);

        
      })
      .addCase(getHallCheckLogs.pending, (hallcheck) => {
        hallcheck.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getHallCheckLogs.rejected, (hallcheck) => {
        hallcheck.status = "Failed to  fetch data..."
      })
      .addCase(updateHallCheckLog.fulfilled, (hallcheck, action) => {
        console.log(action.payload.message, "action.payload.message")
        hallcheck.value.status = action.payload.message
        // find the index of the hallcheck with the updated id
        // const index = hallcheck.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the hallcheck at the found index with the updated hallcheck
        //   hallcheck.value.data[index] = action.payload;
        // }
      })
      .addCase(updateHallCheckLog.pending, (hallcheck) => {
        hallcheck.status = "Updating hallcheck. Please wait a moment...";
      })
      .addCase(updateHallCheckLog.rejected, (hallcheck) => {
        hallcheck.status = "Failed to update hallcheck...";
      })
      .addCase(deleteHallCheckLog.fulfilled, (hallcheck, action) => {
        // // filter out the hallcheck with the deleted id
        // hallcheck.value.data = hallcheck.value.data.filter(item => item.id !== action.payload.id);
        hallcheck.value.status = action.payload.message
      })
      .addCase(deleteHallCheckLog.pending, (hallcheck) => {
        hallcheck.status = "Deleting hallcheck. Please wait a moment...";
      })
      .addCase(deleteHallCheckLog.rejected, (hallcheck) => {
        hallcheck.status = "Failed to delete hallcheck...";
      })
      .addCase(addHallCheckLog.fulfilled, (hallcheck, action) => {
        // hallcheck.value.data.push(action.payload); // add the new hallcheck to the list
     })
     .addCase(addHallCheckLog.pending, (hallcheck) => {
        hallcheck.status = "Adding hallcheck. Please wait a moment...";
     })
     .addCase(addHallCheckLog.rejected, (hallcheck) => {
        hallcheck.status = "Failed to add hallcheck...";
     });
  }
})

export default hallchecksSlice.reducer
