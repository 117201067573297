import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FiCalendar, FiChevronLeft } from "react-icons/fi";
import { addPeriod, getPeriod, updatePeriod, getPeriodByDate } from '../../redux/actions/admin/period';
import { getCourses, getCourseStudents, clearStudents } from '../../redux/actions/admin/course';
import { getEnrollments } from '../../redux/actions/admin/enrollment';
import { getClasses } from '../../redux/actions/admin/classRoom';
import { fetchSettings } from '../../redux/actions/admin/setting';
import toast from 'react-hot-toast';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DropdownSelect from '../../components/select';
import { getHours } from '../../redux/actions/admin/hour';

function CrudAttendance() {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const isAddMode = location.pathname.endsWith('/add');
    const dispatch = useDispatch();

    // Selectors
    const courses = useSelector((state) => state.course?.value?.data || { rows: [] });
    const classes = useSelector((state) => state.classRoom?.value?.data || { rows: [] });
    const students = useSelector((state) => state.enrollment?.value?.data?.rows || []);
    const settings = useSelector((state) => state.setting?.value);
    const hours = useSelector((state) => state?.hour?.value?.data);

    // States
    const [formData, setFormData] = useState({
        courseId: '',
        classId: '',
        hourId: '',
        date: new Date()
    });
    const [studentAttendance, setStudentAttendance] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [existingPeriod, setExistingPeriod] = useState(null);
    const [allPresent, setAllPresent] = useState(false);

    // Initialize data and settings
    useEffect(() => {
        dispatch(fetchSettings());
        dispatch(getCourses({ limit: 50 }));
        dispatch(getClasses({ limit: 50 }));

        // Reset students when component mounts
        dispatch(clearStudents());
    }, [dispatch]);

    // Fetch students based on course or class selection
    useEffect(() => {
        if (formData.courseId || formData.classId || formData.hourId) {
            dispatch(getEnrollments({
                ...(settings?.config?.attendance === "Course" && { courseId: formData.courseId }),
                ...(settings?.config?.attendance === "Class" && { classId: formData.classId }),
                ...(settings?.config?.attendance == "Timetable" && { hourId: formData.hourId }),
                attendanceConfig: settings?.config?.attendance,
                limit: 150
            }));
        } else {
            dispatch(clearStudents());
        }
    }, [dispatch, formData.courseId, formData.classId, formData.hourId]);

    // Initialize student attendance when students change
    useEffect(() => {
        if (students?.length) {
            const initialAttendance = students.map(enrollment => ({
                studentId: enrollment.student.id,
                studentName: `${enrollment.student.admission?.name || enrollment.student.name} - ${enrollment.course?.name || ''}`,
                isPresent: false
            }));
            setStudentAttendance(initialAttendance);
        }
    }, [students]);

    useEffect(() => {
        dispatch(
            getHours(
                {
                    limit: 60,
                    day: formData.date.toLocaleDateString("en-US", { weekday: "long" }),
                    classId: formData.classId
                }
            ))
    }, [formData.date, formData.classId]);
    
    useEffect(() => {
        console.log(formData, "changed formdata")
    }, [formData]);

    // Fetch period data for update mode
    useEffect(() => {
        const initializeData = async () => {
            try {
                if (!isAddMode && id) {
                    const periodResponse = await dispatch(getPeriod(id)).unwrap();
                    setExistingPeriod(periodResponse);

                    // Set form data based on attendance configuration
                    setFormData({
                        hour: periodResponse.hour,
                        class: periodResponse.class,
                        ...(settings?.config?.attendance == "Course" && { courseId: periodResponse.courseId }),
                        ...(settings?.config?.attendance == "Class" && { classId: periodResponse.classId }),
                        ...(settings?.config?.attendance == "Timetable" && { hourId: periodResponse.hourId }),
                        ...(settings?.config?.attendance == "Timetable" && { classId: periodResponse.classId }),
                        date: new Date(periodResponse.from)
                    });

                    // Initialize attendance with existing data
                    const initialAttendance = students.map(enrollment => ({
                        studentId: enrollment.student.id,
                        studentName: `${enrollment.student.admission?.name || enrollment.student.name} - ${enrollment.course?.name || ''}`,
                        isPresent: periodResponse.attendances.some(
                            a => a.studentId === enrollment.student.id && a.status === 'PRESENT'
                        )
                    }));

                    setStudentAttendance(initialAttendance);
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Initialization error:', error);
                toast.error('Failed to load data');
                setIsLoading(false);
            }
        };

        initializeData();
    }, [dispatch, id, isAddMode, students, settings]);

    // Compute period name based on configuration
    const computePeriodName = () => {
        // if (!formData.courseId) return '';
        const formattedDate = moment(formData.date).format('DD MMM YYYY');
        if (settings?.config?.attendance == "Course") {
            const selectedCourse = courses?.rows?.find(course => course.id === formData.courseId);
            return `${selectedCourse?.name || ''} • ${formattedDate}`;
        } else if (settings?.config?.attendance == "Class") {
            const selectedClass = classes?.rows?.find(classRoom => classRoom.id === formData.classId);
            return `${selectedClass?.name || ''} • ${formattedDate}`;
        } else {
            const selectedHour = hours?.rows?.find(hour => hour.id === formData.hourId);
            // console.log(selectedHour, "selectedHour")
            return selectedHour?.timetable?.class?.name + " ( " + extractTimeFromISOString(selectedHour?.from) + " - " + extractTimeFromISOString(selectedHour?.to) + " )"
            // return `${selectedHour?.studentgroup?.course?.name || ''} - ${selectedHour?.studentgroup?.subject?.name || ''} • ${formattedDate}`;
        }
    };

    // Handle course/class change
    const handleCourseChange = async (selectedId) => {
        if (settings?.config?.attendance == "Course") {
            console.log("setting course")
            setFormData(prev => ({ ...prev, selectedId }));
        } else if (settings?.config?.attendance == "Class") {
            console.log("setting class")
            setFormData(prev => ({ ...prev, classId: selectedId }));
        } else {
            console.log("setting hour")
            setFormData(prev => ({ ...prev, hourId: selectedId }));
        }

        // Check for existing period
        if (selectedId && formData.date) {
            try {
                const formattedDate = moment(formData.date).format('YYYY-MM-DD');
                const response = await dispatch(getPeriodByDate({
                    date: formattedDate,
                    ...(settings?.config?.attendance === "Course" && { courseId: selectedId }),
                    ...(settings?.config?.attendance === "Class" && { classId: selectedId }),
                    ...(settings?.config?.attendance == "Timetable" && { hourId: formData.hourId })
                }));

                const existingPeriodResponse = response.payload;

                if (existingPeriodResponse && existingPeriodResponse.id) {
                    // Redirect to the existing period page
                    navigate(`/parent/attendance/${existingPeriodResponse.id}`);
                    return;
                }
            } catch (error) {
                console.error('Failed to check existing period:', error);
                toast.error('Failed to check existing attendance');
            }
        }
    };

    const handleTimeTableClassChange = async (classId) => {
        setFormData(prev => ({ ...prev, classId }));
    }
    // Handle date change
    const handleDateChange = async (date) => {
        setFormData(prev => ({ ...prev, date }));

        if ((formData.courseId || formData.classId) && settings?.config?.attendance) {
            try {
                const formattedDate = moment(date).format('YYYY-MM-DD');
                const response = await dispatch(getPeriodByDate({
                    date: formattedDate,
                    ...(settings?.config?.attendance === "Course" && { courseId: formData.courseId }),
                    ...(settings?.config?.attendance === "Class" && { classId: formData.classId }),
                    ...(settings?.config?.attendance == "Timetable" && { hourId: formData.hourId, classId: formData.classId })
                }));

                const existingPeriodResponse = response.payload;

                if (existingPeriodResponse && existingPeriodResponse.id) {
                    // Redirect to the existing period page
                    navigate(`/parent/attendance/${existingPeriodResponse.id}`);
                    return;
                }
            } catch (error) {
                console.error('Failed to check existing period:', error);
                toast.error('Failed to check existing attendance');
            }
        }
    };

    // Toggle all attendance
    const handleAllPresentToggle = () => {
        setAllPresent(!allPresent);
        setStudentAttendance(prev =>
            prev.map(student => ({ ...student, isPresent: !allPresent }))
        );
    };

    // Toggle individual attendance
    const handleAttendanceToggle = (studentId) => {
        setStudentAttendance(prev =>
            prev.map(student =>
                student.studentId === studentId
                    ? { ...student, isPresent: !student.isPresent }
                    : student
            )
        );
    };

    const extractTimeFromISOString = (isoString) => {
        if (!isoString) return '';
        return isoString.slice(11, 16); // Extract HH:mm from the ISO string
    };

    // Submit attendance
    const handleSubmit = async () => {
        try {
            const periodData = {
                ...(id && { id }),
                ...formData,
                name: computePeriodName(),
                date: moment(formData.date).format('YYYY-MM-DD'),
                attendance: studentAttendance.map(student => ({
                    studentId: student.studentId,
                    status: student.isPresent ? 'PRESENT' : 'ABSENT'
                }))
            };

            if (isAddMode) {
                await dispatch(addPeriod(periodData));
                toast.success('Attendance marked successfully');
            } else {
                await dispatch(updatePeriod(periodData));
                toast.success('Attendance updated successfully');
            }

            navigate('/parent/classes');
        } catch (error) {
            console.error('Submission error:', error);
            toast.error(isAddMode ? 'Failed to mark attendance' : 'Failed to update attendance');
        }
    };

    // Loading state
    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brand-500"></div>
            </div>
        );
    }

    return (
        <div className="bg-lightPrimary min-h-screen">
            {/* Header */}
            <div className="bg-white p-4 shadow-sm flex items-center gap-3 sticky top-0 z-10">
                <button
                    onClick={() => navigate('/parent/classes')}
                    className="p-2 hover:bg-gray-100 rounded-full"
                >
                    <FiChevronLeft className="w-6 h-6" />
                </button>
                <div>
                    <h1 className="text-xl font-medium">
                        {isAddMode ? 'Mark Attendance' : 'Update Attendance'}
                    </h1>
                    {(formData.courseId || formData.classId) && (
                        <p className="text-sm text-gray-500 mt-0.5">
                            {computePeriodName()}
                        </p>
                    )}
                </div>
            </div>

            <div className="p-4 space-y-4">
                {/* Period Details */}
                <div className="bg-white rounded-xl p-4 space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Date
                        </label>
                        <DatePicker
                            selected={formData.date}
                            onChange={handleDateChange}
                            dateFormat="MMMM d, yyyy"
                            className="border-gray-300 rounded-lg focus:border-brand-500 focus:ring-brand-500 block w-full sm:text-sm p-2"
                            maxDate={new Date()}
                            placeholderText="Select date"
                        />
                    </div>
                    {
                        settings?.config?.attendance == "Timetable" &&
                        <>
                            {/* <div className="flex flex-col">
                                <label className="text-sm font-medium text-gray-700 mb-1"> Select Class </label>
                                <DropdownSelect
                                    list={classes?.rows?.map(classRoom => ({
                                        name: classRoom.name,
                                        id: classRoom.id
                                    })) || []}
                                    placeHolder={formData?.class?.name || "Select Class"}
                                    toSelect="id"
                                    onChange={handleTimeTableClassChange}
                                    value={formData.classId}
                                />
                            </div> */}
                            <div className="flex flex-col">
                                <label className="text-sm font-medium text-gray-700 mb-1"> Select Period </label>
                                <DropdownSelect
                                    list={hours?.rows?.map(hour => ({
                                        name: hour?.timetable?.class?.name + " ( " + extractTimeFromISOString(hour?.from) + " - " + extractTimeFromISOString(hour?.to) + " )",
                                        id: hour.id
                                    })) || []}
                                    placeHolder={  
                                        formData.hour ?
                                        formData?.hour?.timetable?.class?.name + " ( " + extractTimeFromISOString(formData?.hour?.from) + " - " + extractTimeFromISOString(formData?.hour?.to) + " )"
                                        : "Select Period"
                                        }
                                    toSelect="id"
                                    onChange={handleCourseChange}
                                    value={formData.hourId}
                                />
                            </div>
                        </>
                    }

                    {settings?.config?.attendance === "Course" && (
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Course
                            </label>
                            <DropdownSelect
                                list={courses?.rows?.map(course => ({
                                    name: course.name,
                                    id: course.id
                                })) || []}
                                placeHolder="Select Course"
                                toSelect="id"
                                onChange={handleCourseChange}
                                value={formData.courseId}
                            />
                        </div>
                    )}

                    {settings?.config?.attendance === "Class" && (
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Class
                            </label>
                            <DropdownSelect
                                list={classes?.rows?.map(classRoom => ({
                                    name: classRoom.name,
                                    id: classRoom.id
                                })) || []}
                                placeHolder="Select Class"
                                toSelect="id"
                                onChange={handleCourseChange}
                                value={formData.classId}
                            />
                        </div>
                    )}


                </div>

                {/* Attendance Section */}
                {studentAttendance.length > 0 && (
                    <div className="space-y-4">
                        <div className="flex items-center justify-between">
                            <h2 className="font-medium text-lg">Mark Attendance</h2>
                            <div className="flex items-center">
                                <label htmlFor="all-present" className="mr-2 text-sm font-medium text-gray-900">
                                    Check/Uncheck All
                                </label>
                                <input
                                    id="all-present"
                                    type="checkbox"
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                    checked={allPresent}
                                    onChange={handleAllPresentToggle}
                                />
                            </div>
                        </div>

                        <div className="space-y-3">
                            {studentAttendance.map(student => (
                                <div
                                    key={student.id}
                                    className="bg-white rounded-xl overflow-hidden shadow-sm"
                                >
                                    <div className="p-4 flex items-center justify-between">
                                        <span className="font-medium">{student.studentName}</span>
                                        <button
                                            onClick={() => handleAttendanceToggle(student.studentId)}
                                            className={`px-4 py-2 rounded-lg font-medium ${student.isPresent
                                                ? 'bg-green-100 text-green-700'
                                                : 'bg-red-100 text-red-700'
                                                }`}
                                        >
                                            {student.isPresent ? 'Present' : 'Absent'}
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <button
                            onClick={handleSubmit}
                            className="w-full p-3 rounded-xl text-white font-medium bg-brand-500 active:bg-brand-600 sticky bottom-4"
                        >
                            {isAddMode ? 'Mark Attendance' : 'Update Attendance'}
                        </button>
                    </div>
                )}

                {/* Existing Period Info (for update mode) */}
                {!isAddMode && existingPeriod && (
                    <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                        <div className="space-y-4">
                            {/* Attendance Summary */}
                            <div className="border-b border-gray-200 pb-4">
                                <h3 className="text-sm font-semibold text-gray-800 mb-3">
                                    Attendance Summary
                                </h3>
                                <div className="flex gap-4">
                                    <div className="flex flex-col items-center bg-green-50 px-4 py-3 rounded-lg border border-green-200 w-full">
                                        <span className="text-2xl font-bold text-green-700">
                                            {studentAttendance.filter(s => s.isPresent).length}
                                        </span>
                                        <span className="text-green-700">Present</span>
                                    </div>
                                    <div className="flex flex-col items-center bg-red-50 px-4 py-3 rounded-lg border border-red-200 w-full">
                                        <span className="text-2xl font-bold text-red-700">
                                            {studentAttendance.filter(s => !s.isPresent).length}
                                        </span>
                                        <span className="text-red-700">Absent</span>
                                    </div>
                                </div>
                            </div>

                            {/* Created By and Updated By */}
                            <div className="flex justify-between">
                                <div>
                                    <h3 className="text-sm font-semibold text-gray-800 mb-1">
                                        Created By
                                    </h3>
                                    <div className="text-gray-600">
                                        <p className="font-medium">{existingPeriod?.createdBy || 'N/A'}</p>
                                        <p className="text-sm text-gray-500">
                                            {existingPeriod?.createdAt
                                                ? moment(existingPeriod.createdAt).format('DD MMM YYYY, h:mm A')
                                                : 'N/A'
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="text-sm font-semibold text-gray-800 mb-1">
                                        Updated By
                                    </h3>
                                    <div className="text-gray-600">
                                        <p className="font-medium">{existingPeriod?.updatedBy || 'N/A'}</p>
                                        <p className="text-sm text-gray-500">
                                            {existingPeriod?.updatedAt
                                                ? moment(existingPeriod.updatedAt).format('DD MMM YYYY, h:mm A')
                                                : 'N/A'
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

// Add custom styles for the DatePicker
const customStyles = `
.react-datepicker-wrapper {
width: 100%;
}
.react-datepicker__input-container {
width: 100%;
}
.react-datepicker__input-container input {
width: 100%;
padding: 0.5rem;
border-radius: 0.5rem;
border: 1px solid #e5e7eb;
}
.react-datepicker__input-container input:focus {
outline: none;
border-color: #6366f1;
ring-color: #6366f1;
}
`;

// Add styles to document head
const styleSheet = document.createElement("style");
styleSheet.innerText = customStyles;
document.head.appendChild(styleSheet);

export default CrudAttendance;