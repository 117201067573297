import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getStudents, addStudent, updateStudent, deleteStudent } from '../../../redux/actions/admin/student';
import { getAllAdmissions } from '../../../redux/actions/admin/admission';
import { getBatches } from '../../../redux/actions/admin/batch';
import { getCourses } from '../../../redux/actions/admin/course';

import toast, { Toaster } from 'react-hot-toast';
import DropdownSelect from 'components/select';
import Paginate from 'components/paginate';
import { useNavigate } from 'react-router-dom';
import {
    FiSearch,
    FiEdit2,
    FiTrash,
} from "react-icons/fi";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";

import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";

export const Student = (props) => {
    const [formData, setFormData] = useState({});
    const [filter, setFilter] = useState({
        name: "",
        status: "All",
        limit: 50,
        page: 1
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getStudents({}))
        dispatch(getAllAdmissions())
        dispatch(getBatches())
        dispatch(getCourses({ limit: 50 }))
        // setFormData({})
    }, [dispatch])



    const students = useSelector((students) => students.student.value.data);
    const courses = useSelector((courses) => courses.course.value.data);
    const batches = useSelector((batches) => batches.batch.value.data);

    const handleActionButtonClick = (studentId, action) => {
        if (action === 'edit') setIsDialogOpen(true);
        if (action === 'delete') setIsDeleteDialogOpen(true);
        if (studentId == null) {
            setIsDialogOpen(true)
            return setFormData({})
        }

        const selectedStudent = students.rows.find((student) => student.id === studentId);
        setFormData(selectedStudent)
    };

    useEffect(() => {
        dispatch(getStudents(filter));
    }, [filter]);

    const handleCrudStudent = async () => {
        try {
            setIsDialogOpen(false)
            if (formData.id) await dispatch(updateStudent(formData));
            if (!formData.id) await dispatch(addStudent(formData));
            notifySuccess("Success")

            setFormData({});
            await dispatch(getStudents({}));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const handleDelete = async () => {
        try {
            setIsDeleteDialogOpen(false)
            await dispatch(deleteStudent(formData.id));
            // setFormData({});
            notifySuccess("Delete student successfully")
            await dispatch(getStudents({}));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    // const students = [];
    const fields = [
        {
            id: "studentname",
            name: "name",
            label: "Student Name",
            placeholder: "Enter Student's Name",
            type: "text",
        }
    ];

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                {/* <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
                    <Widget
                        icon={<FiLayers className="h-7 w-7" />}
                        subtitle={students.count}
                        styling={
                            {
                                iconContStyles: "bg-brand-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-yellow-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiTrendingUp className="h-7 w-7" />}
                        subtitle={students.activeCount}
                        styling={
                            {
                                iconContStyles: "bg-green-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-green-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiX className="h-7 w-7" />}
                        subtitle={students.inactiveCount}
                        styling={
                            {
                                iconContStyles: "bg-red-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-red-100"
                            }
                        }
                    />


                </div> */}

                <div className='mt-3  p-4 rounded-xl border-solid border-2 border-gray-100'>


                    <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-between w-1/2 items-center'>
                                    <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                                        <p className="pl-3 pr-2 text-xl">
                                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                        </p>
                                        <input
                                            // value={filter.name}
                                            onChange={(e) => {
                                                setFilter({ ...filter, ["name"]: e.target.value })
                                            }}
                                            type="text"
                                            placeholder="Search..."
                                            className="block h-full w-full  bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                        />
                                    </div>
                                    <DropdownSelect
                                        list={batches.rows || []}
                                        triggerStyles="bg-lightPrimary !rounded-full"
                                        // listStyles="text-sm text-red-700"
                                        placeHolder={"Batch"}

                                        toSelect={"id"}
                                        onChange={(value) => {
                                            if (value === "all") return setFilter({ ...filter, ['batchId']: null })
                                            setFilter({ ...filter, ['batchId']: value })
                                        }}
                                    />
                                    <DropdownSelect
                                        list={courses.rows}
                                        triggerStyles="bg-lightPrimary !rounded-full !m-3"
                                        // listStyles="text-sm text-red-700"
                                        placeHolder={"Course"}

                                        toSelect={"id"}
                                        onChange={(value) => {
                                            if (value === "all") return setFilter({ ...filter, ['courseId']: null })
                                            setFilter({ ...filter, ['courseId']: value })
                                        }}
                                    />
                                </div>
                                <div className='flex  justify-end'>
                                    {/* <button
                                        onClick={() => handleActionButtonClick(null, "add")}
                                        className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Add Student
                                    </button> */}

                                </div>
                            </div>
                        </TableCaption>
                        {students.rows.length > 0 &&
                            <div className="">
                                <TableHeader>

                                    <TableRow className="dark:text-white">
                                        <TableHead className="w-4/12"> Student </TableHead>
                                        <TableHead className="w-4/12"> Parent </TableHead>
                                        <TableHead className="w-4/12"> Enrollment </TableHead>
                                        <TableHead className="w-1/3 text-center ">Actions</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {students.rows.map((student) => (
                                        <TableRow key={student.id}>
                                            <TableCell
                                                className="font-bold text-brand-500 cursor-pointer"
                                                onClick={() => navigate(`/admin/student-dashboard/${student.id}`)}
                                            >
                                                <h4 className=''> {student.admission.name} </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {student?.user?.fullname} </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className='whitespace-pre-line'>
                                                    {student.enrollments.length ?
                                                        student.enrollments.map(enrollment => enrollment.course.name).join('\n')
                                                        : "Nil"
                                                    }
                                                </h4>
                                            </TableCell>
                                            <TableCell className="w-1/6  ">
                                                <div className='w-full flex justify-around'>
                                                    <div
                                                        onClick={() => navigate(`/admin/admission/${student.admission.id}`)}
                                                        className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FiEdit2 className='h-4 w-4 text-white' />
                                                    </div>
                                                    {/* <div
                                                        onClick={() => handleActionButtonClick(student.id, "delete")}
                                                        className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
                                                        <FiTrash className='h-4 w-4 text-white' />
                                                    </div> */}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}


                                </TableBody>
                                <Paginate
                                    page={filter.page}
                                    totalCount={students.count}
                                    limit={filter.limit}
                                    changePage={(value) => {
                                        setFilter({ ...filter, ["page"]: value })
                                    }}
                                />
                            </div>
                        }
                    </Table>
                    {students.rows.length == 0 &&
                        <div className='p-10 flex flex-col justify-center items-center'>
                            <p className='mb-5 font-semibold'> No Students Found! </p>
                            <button
                                onClick={() => handleActionButtonClick(null, "add")}
                                className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                Add Student
                            </button>
                        </div>
                    }

                    <DialogCustom

                        open={isDialogOpen}
                        onOpenChange={setIsDialogOpen}
                        dialogTitle="Edit Student"
                        dialogWidth="w-1/2"
                        dialogDesc="Update the neccessary fields and save."
                        content={
                            <>
                                <Form
                                    fields={fields}
                                    formData={formData}
                                    onChange={(newFormData) => setFormData(newFormData)}
                                />
                                <div className='flex justify-end'>
                                    <button
                                        onClick={handleCrudStudent}
                                        className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70">
                                        {formData.id ? "Update" : "Add" + " Student"}
                                    </button>
                                </div></>
                        }
                    />
                    <DialogCustom

                        open={isDeleteDialogOpen}
                        onOpenChange={setIsDeleteDialogOpen}
                        dialogTitle="Delete Student"
                        dialogWidth="w-1/2"
                        dialogDesc="Are you sure you want to delete this student ?"
                        content={
                            <div className='flex flex-row-reverse'>
                                <button
                                    onClick={handleDelete}
                                    className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                                    Delete
                                </button>
                                <button
                                    onClick={() => {
                                        setIsDeleteDialogOpen(false);
                                    }}
                                    className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                    Cancel
                                </button>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    )
};

export default Student