import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment';
import { getAdmissions, addAdmission, updateAdmission, deleteAdmission, addAdmissionBulk } from '../../../redux/actions/admin/admission';
import { validateToken } from '../../../redux/actions/auth';
import toast, { Toaster } from 'react-hot-toast';
import DropdownSelect from 'components/select';
import Paginate from 'components/paginate';
import { useNavigate, useParams } from 'react-router-dom';
import {
    FiCheck,
    FiSearch,
    FiEdit2,
    FiTrash,
    FiX
} from "react-icons/fi";
import LoadingIcons from 'react-loading-icons'
import * as XLSX from "xlsx";
import Widget from "components/widget/Widget";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";

import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";
import { getCourses } from '../../../redux/actions/admin/course';
import { getBatches } from '../../../redux/actions/admin/batch';

export const Admission = (props) => {
    // const history = useHistory();

    const navigate = useNavigate();
    const { id } = useParams()
    console.log(id, "COURSE ID")
    const [formData, setFormData] = useState({});
    // const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({
        name: "",
        status: "All",
        courseId: id,
        limit: 50,
        page: 1
    });
    const [code, setCode] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [isBulkImportOpen, setIsBulkImportOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedCourseValue, setSelectedCourseValue] = useState({});
    const [isBatchDialogOpen, setIsBatchDialogOpen] = useState(false);
    const [selectedBatch, setSelectedBatch] = useState(null);

    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdmissions(filter))
        dispatch(getCourses({}))
        dispatch(getBatches({ limit: 50 }))
        // setFormData({})
    }, [dispatch])




    const admissions = useSelector((admissions) => admissions.admission.value.data);
    const user = useSelector((user) => user.auth.value);
    const batches = useSelector((batches) => batches.batch.value.data);

    // const courses = useSelector((courses) => courses.course.value.data.rows);

    const handleActionButtonClick = (admissionId, action) => {
        const selectedAdmission = admissions.rows.find((admission) => admission.id === admissionId);
        setFormData(selectedAdmission)

        if (action === 'edit') {
            setIsDialogOpen(true);
            setSelectedCourseValue(id)
        }
        if (action === 'delete') setIsDeleteDialogOpen(true);
        if (admissionId == null) {
            setIsDialogOpen(true)
            return setFormData({})
        }

    };

    useEffect(() => {
        dispatch(getAdmissions(filter));
    }, [filter]);

    const handleCrudAdmission = async () => {
        try {
            setIsDialogOpen(false)
            if (formData.id) await dispatch(updateAdmission({ ...formData, ["courseId"]: id }));
            if (!formData.id) {
                await dispatch(addAdmission({ ...formData, ["courseId"]: id }));
            }
            notifySuccess("Success")

            setFormData({});
            await dispatch(getAdmissions(filter));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const handleDelete = async () => {
        try {
            setIsDeleteDialogOpen(false)
            await dispatch(deleteAdmission(formData.id));
            // setFormData({});
            notifySuccess("Delete admission successfully")
            await dispatch(getAdmissions({}));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const [file, setFile] = useState(null);
    const [importedAdmissions, setImportedAdmissions] = useState([])
    const [jsonData, setJsonData] = useState("");

    const handleConvert = () => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setJsonData(() => {
                    //   console.log("Converted JSON data ", JSON.stringify(json, null, 2));
                    setImportedAdmissions(JSON.parse(JSON.stringify(json, null, 2)))
                    return JSON.stringify(json, null, 2);
                });
            };
            reader.readAsBinaryString(file);
        }
    };

    const handleFileUpload = (e) => {
        setFile(e.target.files[0]);
    };

    useEffect(() => {
        handleConvert();
    }, [file]);

    const downloadSampleFile = () => {
        // Create sample data
        const sampleData = [
            {
                name: "Student Name",
                DOB: "2000-01-01",
                email: "student@ifelsetech.com",
                phone: "9884899868",
                parentName: "Parent Name",
                course: "course name",
                session: "session name",
                class: "class name",
                batch: "batch name",
            }
        ];

        // Create workbook and worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(sampleData);

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, "Students");

        // Save file
        XLSX.writeFile(wb, "sample_students_import.xlsx");
    };


    const bulkImportAdmissions = async () => {
        try {
            setIsBulkImportOpen(true);

            const response = await dispatch(addAdmissionBulk(importedAdmissions));
            // console.log(response.payload, "RES")
            // return 
            if (response.payload.success) {
                if (response.payload.skipped > 0) {
                    // Create and download Excel file for skipped students
                    const wb = XLSX.utils.book_new();
                    const ws = XLSX.utils.json_to_sheet(
                        response.payload.skippedStudents.map(item => ({
                            ...item.studentData,
                            skippedReason: item.reason
                        }))
                    );
                    XLSX.utils.book_append_sheet(wb, ws, "Skipped Students");
                    XLSX.writeFile(wb, "skipped_students.xlsx");

                    // Notify about partially successful import
                    setIsBulkImportOpen(false)
                    notifyError(`${response.payload.imported} admissions imported. ${response.payload.skipped} students skipped.`);
                } else {
                    // Fully successful import
                    setIsBulkImportOpen(false)
                    notifySuccess("All admissions imported successfully!");
                }
            } else {
                // Handle complete import failure
                notifyError("Failed to import admissions.");
            }
        } catch (error) {
            // Handle any dispatch or network errors
            notifyError("An error occurred during import.");
        } finally {
            setIsBulkImportOpen(false);
            dispatch(getAdmissions(filter))
        }
    };

    function copyBaseURL() {
        if (!selectedBatch) {
            setIsBatchDialogOpen(true);
            return;
        }

        const baseURL = window.location.origin;
        const admissionLink = `${baseURL}/open/admission?code=${user.user.code}&bid=${selectedBatch}`;

        if (navigator.clipboard) {
            navigator.clipboard.writeText(admissionLink)
                .then(() => {
                    notifySuccess("Admission Link Copied!");
                })
                .catch((error) => {
                    notifyError("Failed to copy URL");
                });
        } else {
            const tempInput = document.createElement('input');
            document.body.appendChild(tempInput);
            tempInput.value = admissionLink;
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);
            notifySuccess("Admission Link Copied!");
        }
        setIsBatchDialogOpen(false);
        setSelectedBatch(null);
    }



    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                {/* <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
                        <Widget
                            icon={<FiLayers className="h-7 w-7" />}
                            subtitle={admissions.count}
                            styling={
                                {
                                    iconContStyles: "bg-brand-500",
                                    iconStyles: "text-white",
                                    border: "border-solid border-2 border-yellow-100"
                                }
                            }
                        />
                        <Widget
                            icon={<FiTrendingUp className="h-7 w-7" />}
                            subtitle={admissions.activeCount}
                            styling={
                                {
                                    iconContStyles: "bg-green-500",
                                    iconStyles: "text-white",
                                    border: "border-solid border-2 border-green-100"
                                }
                            }
                        />
                        <Widget
                            icon={<FiX className="h-7 w-7" />}
                            subtitle={admissions.inactiveCount}
                            styling={
                                {
                                    iconContStyles: "bg-red-500",
                                    iconStyles: "text-white",
                                    border: "border-solid border-2 border-red-100"
                                }
                            }
                        />


                    </div> */}

                <div className='mt-3  p-4 rounded-xl border-solid border-2 border-gray-100'>
                    <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-between w-1/2 items-center'>
                                    <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                                        <p className="pl-3 pr-2 text-xl">
                                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                        </p>
                                        <input
                                            // value={filter.name}
                                            onChange={(e) => {
                                                setFilter({ ...filter, ["name"]: e.target.value })
                                            }}
                                            type="text"
                                            placeholder="Search..."
                                            className="block h-full w-full  bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                        />
                                    </div>
                                    {/* <DropdownSelect
                                                    list={[
                                                        { name: "All", id: 1 },
                                                        { name: "Active", id: 2 },
                                                        { name: "Inactive", id: 3 },
                                                    ]}
                                                    triggerStyles="bg-lightPrimary !rounded-full"
                                                    // listStyles="text-sm text-red-700"
                                                    placeHolder={"Status"}
                                                    toSelect={"name"}
                                                    onChange={(value) => {
                                                        setFilter({ ...filter, ["status"]: value })
                                                    }}
                                                /> */}
                                </div>
                                <div className='flex  justify-end'>
                                    <button
                                        onClick={() => {
                                            copyBaseURL()
                                        }}
                                        className="border-2 text-white linear rounded-full bg-brand-300 mr-5 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Copy Admission Link
                                    </button>
                                    <button
                                        onClick={() => {
                                            // setFormData({...formData, ["courseId"]: id})
                                            // handleActionButtonClick(null, "add")}
                                            navigate(`/admin/admission/${null}`)
                                        }}
                                        className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Add Admission
                                    </button>
                                    <button
                                        onClick={() => {
                                            setFile("")
                                            setImportedAdmissions([])
                                            setJsonData([])
                                            setIsImportDialogOpen(true)
                                        }}
                                        className="ml-5 border-2 text-white linear rounded-full bg-brand-400 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Import Admission
                                    </button>

                                </div>
                            </div>
                        </TableCaption>
                        {admissions.rows.length > 0 &&
                            <div className="">
                                <TableHeader>
                                    <TableRow className="dark:text-white">
                                        <TableHead className="w-1/12"> S.no. </TableHead>
                                        <TableHead className="w-4/12"> Candidate's name </TableHead>
                                        <TableHead className="w-4/12"> Parent's Details </TableHead>
                                        <TableHead className="w-4/12"> Is Enquiry </TableHead>
                                        <TableHead className="w-4/12"> Is Student </TableHead>
                                        <TableHead className="w-2/12 "> Date </TableHead>
                                        <TableHead className="w-2/12 "> Action </TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {admissions.rows.map((admission, index) => (
                                        <TableRow key={admission.id}>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {(index + 1) + (10 * (filter.page - 1))} </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {admission.name} </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {admission.parentName} </h4>
                                                <h4 className=''> {admission.parentContact} </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                {admission.admissionEnquiryNumber ?
                                                    <>
                                                        <div className="flex items-center justify-start w-fit bg-green-500 rounded-2xl  ">
                                                            <FiCheck className="h-6 w-6 text-white p-1 " />
                                                            {/* <span>Active</span> */}
                                                        </div>
                                                        <p> {admission.admissionEnquiryNumber} </p>
                                                    </>
                                                    :
                                                    <div className="flex items-center justify-start w-fit rounded-xl bg-red-500">
                                                        <FiX className="h-6 w-6 text-white  p-1" />
                                                        {/* <span>Inactive</span> */}
                                                    </div>
                                                }
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                {admission.student ?
                                                    <div className="flex items-center justify-start w-fit bg-green-500 rounded-2xl  ">
                                                        <FiCheck className="h-6 w-6 text-white p-1 " />
                                                        {/* <span>Active</span> */}
                                                    </div> :
                                                    <div className="flex items-center justify-start w-fit rounded-xl bg-red-500">
                                                        <FiX className="h-6 w-6 text-white  p-1" />
                                                        {/* <span>Inactive</span> */}
                                                    </div>
                                                }
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {moment(admission.createdAt).format("DD/MM/YYYY HH:mm")} </h4>
                                            </TableCell>
                                            <TableCell className="w-1/6">
                                                <div className='w-full flex justify-around'>
                                                    {(
                                                        <div
                                                            onClick={() => navigate(`/admin/admission/${admission.id}`)}
                                                            className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer'
                                                        >
                                                            <FiEdit2 className='h-4 w-4 text-white' />
                                                        </div>
                                                    )}

                                                    <div
                                                        onClick={() => handleActionButtonClick(admission.id, "delete")}
                                                        className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'
                                                    >
                                                        <FiTrash className='h-4 w-4 text-white' />
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}


                                </TableBody>
                                <Paginate
                                    page={filter.page}
                                    totalCount={admissions.count}
                                    limit={filter.limit}
                                    changePage={(value) => {
                                        setFilter({ ...filter, ["page"]: value })
                                    }}
                                />
                            </div>
                        }
                    </Table>
                    {admissions.rows.length == 0 &&
                        <div className='p-10 flex flex-col justify-center items-center'>
                            <p className='mb-5 font-semibold'> No Admissions Found! </p>
                            <button
                                onClick={() => navigate(`/admin/admission/${null}`)}
                                className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                Add Admission
                            </button>
                        </div>
                    }

                    {/* <DialogCustom

                            open={isDialogOpen}
                            onOpenChange={setIsDialogOpen}
                            dialogTitle="Edit Admission"
                            dialogWidth="w-1/2"
                            dialogDesc="Update the neccessary fields and save."
                            content={
                                <>
                                    <Form
                                        fields={fields}
                                        formData={formData}
                                        onChange={(newFormData) => setFormData(newFormData)}
                                    />
                                    <div className='flex justify-end'>
                                        <button
                                            onClick={handleCrudAdmission}
                                            className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70">
                                            {formData.id ? "Update" : "Add" + " Admission"}
                                        </button>
                                    </div></>
                            }
                        /> */}
                    <DialogCustom

                        open={isImportDialogOpen}
                        onOpenChange={setIsImportDialogOpen}
                        dialogTitle="Import Admissions"
                        dialogWidth="w-1/2"
                        dialogDesc="Upload the Admissions XLS file to import admissions in bulk"
                        content={
                            <>
                                <div className='flex justify-between'>
                                    <div className="relative">
                                        <input
                                            type="file"
                                            accept=".xls,.xlsx"
                                            onChange={handleFileUpload}
                                            className="appearance-none opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                                        />
                                        <div className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                            Upload File
                                        </div>
                                    </div>


                                    {
                                        importedAdmissions.length > 0
                                        &&
                                        <button
                                            onClick={
                                                () => {
                                                    bulkImportAdmissions()
                                                }
                                            }
                                            className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70"
                                        > {`Upload ${importedAdmissions.length} admissions ?`} </button>
                                    }
                                    <button
                                        onClick={downloadSampleFile}
                                        className="text-brand-500 hover:text-brand-700 underline text-sm"
                                    >
                                        Download Sample
                                    </button>
                                    {/* <p>{jsonData}</p> */}
                                </div>
                            </>
                        }
                    />
                    <DialogCustom

                        open={isDeleteDialogOpen}
                        onOpenChange={setIsDeleteDialogOpen}
                        dialogTitle="Delete Admission"
                        dialogWidth="w-1/2"
                        dialogDesc="Are you sure you want to delete this admission ?"
                        content={
                            <div className='flex flex-row-reverse'>
                                <button
                                    onClick={handleDelete}
                                    className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                                    Delete
                                </button>
                                <button
                                    onClick={() => {
                                        setIsDeleteDialogOpen(false);
                                    }}
                                    className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                    Cancel
                                </button>
                            </div>
                        }
                    />
                    <DialogCustom
                        open={isBatchDialogOpen}
                        onOpenChange={setIsBatchDialogOpen}
                        dialogTitle="Select Batch"
                        dialogWidth="w-1/2"
                        dialogDesc="Please select a batch to generate the admission link"
                        content={
                            <>
                                <div className="flex flex-col space-y-4">
                                    <div className="grid grid-cols-1 gap-4">
                                        {batches.rows && batches.rows.map((batch) => (
                                            <button
                                                key={batch.id}
                                                onClick={() => {
                                                    setSelectedBatch(batch.id);
                                                    copyBaseURL();
                                                }}
                                                className={`p-4 text-left border rounded-lg hover:bg-brand-50 transition-all
                                                    ${selectedBatch === batch.id ? 'border-brand-500 bg-brand-50' : 'border-gray-200'}`}
                                            >
                                                <h3 className="font-medium">{batch.name}</h3>
                                                <p className="text-sm text-gray-500">{batch.description}</p>
                                            </button>
                                        ))}
                                    </div>
                                    <div className="flex justify-end space-x-2">
                                        <button
                                            onClick={() => setIsBatchDialogOpen(false)}
                                            className="px-4 py-2 text-gray-500 hover:text-gray-700"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </>
                        }
                    />
                    <DialogCustom
                        open={isBulkImportOpen}
                        onOpenChange={setIsBulkImportOpen}
                        dialogTitle="Uploading Admissions"
                        dialogWidth="w-1/2"
                        dialogDesc="Please wait for the admission to be uploaded"
                        content={
                            <>
                                <div className="flex justify-center flex-col">
                                    <div className='w-full'>
                                        <LoadingIcons.SpinningCircles className='w-full' fill="#7cb400" />
                                    </div>
                                    <p className="text-center text-brand-500 ml-2">Uploading admissions...</p>
                                </div>
                                <div className="flex justify-end">
                                    <button
                                        onClick={() => {
                                            setIsBulkImportOpen(false);
                                        }}
                                        className="mr -4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  "
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    )
};

export default Admission