import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getExamSets, addExamSet, updateExamSet, deleteExamSet, publishExamResult, downloadRankList, uploadResults } from '../../../../redux/actions/admin/examset';
import { getCourseEnrolledStudents } from '../../../../redux/actions/admin/exam';
import toast, { Toaster } from 'react-hot-toast';
import Paginate from 'components/paginate';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import {
    FiSearch,
    FiEdit2,
    FiTrash,
    FiUpload,
    FiDownload,
    FiLoader
} from "react-icons/fi";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../../components/shadcn/table";
import { FaFileDownload } from 'react-icons/fa';

import DialogCustom from "../../../../components/dialog";
import { MdPublish } from 'react-icons/md';


const notifySuccess = (message) => toast.success(message, {
    style: {
        padding: '35px',
        color: '#a0ca00',
    },
    duration: 3000,
    iconTheme: {
        primary: '#a0ca00',
        secondary: '#222c25',
    }
})
const notifyError = (message) => toast.error(message, {
    style: {
        border: '1px solid #fff',
        padding: '35px',
        color: 'red',
    },
    iconTheme: {
        primary: 'red',
        secondary: '#fff',
    }
})

export const ExamSet = (props) => {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({});
    const [isMarksUploadOpen, setIsMarksUploadOpen] = useState(false);
    const [filter, setFilter] = useState({
        name: "",
        status: "All",
        limit: 10,
        page: 1
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isPublishDialogOpen, setPublishIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);



    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getExamSets({}))
        // setFormData({})
    }, [dispatch])

    const handleMarksUploadClick = async (selectedExamset) => {
        const selectedExamSet = examsets.rows.find((examset) => examset.id === selectedExamset.id);
        setFormData(selectedExamSet);
        await dispatch(getCourseEnrolledStudents(selectedExamset?.course?.id));
        setIsMarksUploadOpen(true);
    };

    const examsets = useSelector((examsets) => examsets.examset.value.data);
    const students = useSelector((exams) => exams.exam.value.courseEnrolledStudents);

    const handleActionButtonClick = (examsetId, action) => {
        if (action === 'edit') setIsDialogOpen(true);
        if (action === 'delete') setIsDeleteDialogOpen(true);
        if (examsetId == null) {
            setIsDialogOpen(true)
            return setFormData({})
        }
        const selectedExamSet = examsets.rows.find((examset) => examset.id === examsetId);
        setFormData(selectedExamSet)

        if (action == "publish") {
            setPublishIsDialogOpen(true)
            return 0
        }

    };

    useEffect(() => {
        dispatch(getExamSets(filter));
    }, [filter]);

    const handleDownloadRankList = async (examsetId) => {
        try {
            const resultAction = await dispatch(downloadRankList(examsetId));
            if (downloadRankList.fulfilled.match(resultAction)) {
                notifySuccess('Rank list downloaded successfully');
            } else {
                notifyError('Failed to download rank list');
            }
        } catch (error) {
            console.error('Error downloading rank list:', error);
            notifyError('Failed to download rank list');
        }
    };

    const handleCrudExamSet = async () => {
        try {
            setIsDialogOpen(false)
            if (formData.id) await dispatch(updateExamSet(formData));
            if (!formData.id) await dispatch(addExamSet(formData));
            notifySuccess("Success")

            setFormData({});
            await dispatch(getExamSets({}));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const handleDelete = async () => {
        try {
            setIsDeleteDialogOpen(false)
            await dispatch(deleteExamSet(formData.id));
            // setFormData({});
            notifySuccess("Delete examset successfully")
            await dispatch(getExamSets({}));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const handlePublish = async () => {
        try {
            setPublishIsDialogOpen(false)
            // console.log("SEND RESULT", formData)
            // return 0
            await dispatch(publishExamResult(formData));
            // setFormData({});
            notifySuccess("Results Published Successfully")
            // await dispatch(getExamSets({}));
            setFormData({})

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                {/* <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
                    <Widget
                        icon={<FiLayers className="h-7 w-7" />}
                        subtitle={examsets.count}
                        styling={
                            {
                                iconContStyles: "bg-brand-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-yellow-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiTrendingUp className="h-7 w-7" />}
                        subtitle={examsets.activeCount}
                        styling={
                            {
                                iconContStyles: "bg-green-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-green-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiX className="h-7 w-7" />}
                        subtitle={examsets.inactiveCount}
                        styling={
                            {
                                iconContStyles: "bg-red-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-red-100"
                            }
                        }
                    />


                </div> */}

                <div className='mt-3  p-4 rounded-xl border-solid border-2 border-gray-100'>


                    <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-between w-1/2 items-center'>
                                    <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                                        <p className="pl-3 pr-2 text-xl">
                                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                        </p>
                                        <input
                                            // value={filter.name}
                                            onChange={(e) => {
                                                setFilter({ ...filter, ["name"]: e.target.value })
                                            }}
                                            type="text"
                                            placeholder="Search..."
                                            className="block h-full w-full  bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                        />
                                    </div>
                                    {/* <DropdownSelect
                                                list={[
                                                    { name: "All", id: 1 },
                                                    { name: "Active", id: 2 },
                                                    { name: "Inactive", id: 3 },
                                                ]}
                                                triggerStyles="bg-lightPrimary !rounded-full"
                                                // listStyles="text-sm text-red-700"
                                                placeHolder={"Status"}
                                                toSelect={"name"}
                                                onChange={(value) => {
                                                    setFilter({ ...filter, ["status"]: value })
                                                }}
                                            /> */}
                                </div>
                                <div className='flex  justify-end'>
                                    <button
                                        onClick={() => {
                                            navigate(`/admin/exam/examset/0`);
                                        }}
                                        className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Add ExamSet
                                    </button>

                                </div>
                            </div>
                        </TableCaption>
                        {examsets.rows.length > 0 &&
                            <div className="">
                                <TableHeader>

                                    <TableRow className="dark:text-white">
                                        <TableHead className="w-4/12"> Exam Set </TableHead>
                                        <TableHead className="w-4/12"> Exam Type </TableHead>
                                        <TableHead className="w-2/12"> Course </TableHead>
                                        <TableHead className="w-2/12"> Submitted </TableHead>
                                        <TableHead className="w-4   /12"> Total Exams </TableHead>
                                        <TableHead className="w-1/3 text-center ">Actions</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {examsets.rows.map((examset) => (
                                        <TableRow key={examset.id}>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {examset.name} </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {examset?.exammaster?.name} </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {examset?.course?.name} </h4>
                                            </TableCell>
                                            <TableCell className={`font-bold ${examset?.exams.every(exam => exam.isEvaluated) ? "text-green-500" : "text-red-500"}`} >
                                                {examset?.exams.every(exam => exam.isEvaluated) ? "Submitted" : "Pending"}
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {examset?.exams?.length} </h4>
                                            </TableCell>
                                            <TableCell className="w-1/6  ">
                                                <div className='w-full flex justify-around'>
                                                    <div
                                                        onClick={() => {
                                                            if (!examset?.exams.every(exam => exam.isEvaluated)) return notifyError("Evaluation Incomplete")
                                                            handleActionButtonClick(examset.id, "publish")
                                                        }}
                                                        title='Publish Results'
                                                        className='mx-2 bg-brand-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <MdPublish className='h-4 w-4 text-white' />
                                                    </div>
                                                    <div
                                                        title='Upload Results'
                                                        onClick={() => handleMarksUploadClick(examset)}
                                                        className='mx-2 bg-brand-500 w-fit p-1 rounded-3xl cursor-pointer'
                                                    >
                                                        <FiUpload className='h-4 w-4 text-white' />
                                                    </div>
                                                    <div
                                                        title='Download Results'
                                                        onClick={() => handleDownloadRankList(examset.id)}
                                                        className='mx-2 bg-green-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FaFileDownload className='h-4 w-4 text-white' />
                                                    </div>
                                                    <div
                                                        title='Edit Examset'

                                                        // onClick={() => handleActionButtonClick(examset.id, "edit")}
                                                        onClick={() => {
                                                            navigate(`/admin/exam/examset/${examset.id}`);
                                                        }}
                                                        className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FiEdit2 className='h-4 w-4 text-white' />
                                                    </div>
                                                    {/* <div
                                                        onClick={() => {
                                                            navigate(`/admin/permission/${examset.id}`);
                                                        }}
                                                        className='mx-2 bg-blue-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FiSettings className='h-4 w-4 text-white' />
                                                    </div> */}
                                                    <div
                                                        title='Delete Examset'

                                                        onClick={() => handleActionButtonClick(examset.id, "delete")}
                                                        className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
                                                        <FiTrash className='h-4 w-4 text-white' />
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}


                                </TableBody>
                                <Paginate
                                    page={filter.page}
                                    totalCount={examsets.count}
                                    limit={filter.limit}
                                    changePage={(value) => {
                                        setFilter({ ...filter, ["page"]: value })
                                    }}
                                />
                            </div>
                        }
                    </Table>
                    {examsets.rows.length == 0 &&
                        <div className='p-10 flex flex-col justify-center items-center'>
                            <p className='mb-5 font-semibold'> No ExamSets Found! </p>
                            <button
                                onClick={() => {
                                    navigate(`/admin/exam/examset/0`);
                                }}
                                className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                Add ExamSet
                            </button>
                        </div>
                    }
                    <DialogCustom
                        open={isMarksUploadOpen}
                        onOpenChange={setIsMarksUploadOpen}
                        dialogTitle={`Upload Marks - ${formData?.name || ''}`}
                        dialogWidth="w-2/3"
                        dialogDesc="Upload marks for all subjects in this exam set"
                        content={
                            <div>
                                <MarksUploadDialog
                                    examset={formData}
                                    students={students}
                                    onUpload={(data) => {
                                        // Handle the uploaded data here
                                        dispatch(uploadResults(data));
                                        // You'll need to create an action to save this data
                                        notifySuccess("Marks uploaded successfully");
                                        setIsMarksUploadOpen(false);
                                    }}
                                />
                                <div className='flex flex-row-reverse mt-4'>
                                    <button
                                        onClick={() => setIsMarksUploadOpen(false)}
                                        className="text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        }
                    />

                    <DialogCustom

                        open={isDeleteDialogOpen}
                        onOpenChange={setIsDeleteDialogOpen}
                        dialogTitle="Delete ExamSet"
                        dialogWidth="w-1/2"
                        dialogDesc="Are you sure you want to delete this examset ?"
                        content={
                            <div className='flex flex-row-reverse'>
                                <button
                                    onClick={handleDelete}
                                    className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                                    Delete
                                </button>
                                <button
                                    onClick={() => {
                                        setIsDeleteDialogOpen(false);
                                    }}
                                    className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                    Cancel
                                </button>
                            </div>
                        }
                    />
                    <DialogCustom

                        open={isPublishDialogOpen}
                        onOpenChange={setPublishIsDialogOpen}
                        dialogTitle="Publish Exam Results ?"
                        dialogWidth="w-1/2"
                        dialogDesc="Are you sure you want to publish/send the results ?"
                        content={
                            <div className='flex flex-row-reverse'>
                                <button
                                    onClick={handlePublish}
                                    className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                                    Send
                                </button>
                                <button
                                    onClick={() => {
                                        setPublishIsDialogOpen(false);
                                    }}
                                    className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                    Cancel
                                </button>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    )
};

export default ExamSet


const MarksUploadDialog = ({ examset, students, onUpload }) => {
    const [error, setError] = useState("");
    const [file, setFile] = useState(null);
    const [uploadedContent, setUploadedContent] = useState([]);
    const [loading, setLoading] = useState(false); // Add loading state

    const generateTemplate = () => {
        // Create headers from exam names
        const headers = ['Student Name', ...examset.exams.map(exam => `${exam.name}_${exam.id}`)];

        // Create rows with student data
        const rows = students.map(enrollment => ({
            'Student Name': `${enrollment.student.admission.name}_${enrollment.student.id}`,
            ...examset.exams.reduce((acc, exam) => ({
                ...acc,
                [`${exam.name}_${exam.id}`]: ''
            }), {})
        }));

        // Create a worksheet and add the headers as the first row
        const ws = XLSX.utils.aoa_to_sheet([headers]);

        // Add the data rows starting from the second row
        XLSX.utils.sheet_add_json(ws, rows, { origin: 'A2', skipHeader: true });

        // Create a workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Template');

        // Generate and download the file
        XLSX.writeFile(wb, `${examset.name}_template.xlsx`);
    };

    const validateAndParseExcel = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const workbook = XLSX.read(e.target.result, { type: 'binary' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];

                    // Read headers separately
                    const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
                    console.log("Headers from file:", headers);

                    // Read data rows
                    const data = XLSX.utils.sheet_to_json(worksheet, { defval: "", header: headers });
                    console.log("Data from file:", data);

                    // Validate that required columns exist in the header
                    let requiredColumns = examset.exams.map(exam => `${exam.name}_${exam.id}`);
                    requiredColumns = ["Student Name", ...requiredColumns];
                    const fileColumns = headers || [];
                    console.log("File columns:", fileColumns);

                    const missingColumns = requiredColumns.filter(
                        col => !fileColumns.includes(col)
                    );

                    if (missingColumns.length > 0) {
                        setError(`Missing required columns: ${missingColumns.join(', ')}`);
                        notifyError(`Missing required columns: ${missingColumns.join(', ')}`);
                        reject(`Missing required columns: ${missingColumns.join(', ')}`);
                        return;
                    }

                    setUploadedContent(data);
                    resolve(data);
                } catch (error) {
                    notifyError('Error parsing Excel file: ' + error.message);
                    reject('Error parsing Excel file: ' + error.message);
                }
            };
            reader.onerror = (error) => {
                notifyError('Error reading file: ' + error.message);
                reject(error);
            };
            reader.readAsBinaryString(file);
        });
    };

    const handleFileUpload = async () => {
        try {
            if (!file) {
                setError("Please select a file first");
                notifyError("Please select a file first");
                return;
            }

            setLoading(true); // Set loading to true
            const data = await validateAndParseExcel(file);
            await onUpload(data);
            setError("");
            notifySuccess("Marks uploaded successfully");
            setFile(null); // Reset file state after successful upload
        } catch (error) {
            setError(error.message || "Failed to upload file");
            notifyError(error.message || "Failed to upload file");
        } finally {
            setLoading(false); // Set loading to false
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setError(""); // Clear any previous errors
        }
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center mb-4">
                <button
                    onClick={generateTemplate}
                    className="flex items-center gap-2 text-white linear rounded-xl border-2 border-white bg-blue-500 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-blue-500 hover:border-2 hover:border-blue-500"
                >
                    <FiDownload className="h-4 w-4" />
                    Download Template
                </button>
            </div>

            <div className="w-full">
                <label
                    htmlFor="marks-file"
                    className="flex flex-col items-center justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-xl appearance-none cursor-pointer hover:border-brand-500"
                >
                    <div className="flex flex-col items-center space-y-2">
                        <FiUpload className="h-6 w-6 text-gray-400" />
                        <span className="text-sm text-gray-500">
                            Click to upload marks file
                        </span>
                    </div>
                    <input
                        id="marks-file"
                        type="file"
                        className="hidden"
                        accept=".xlsx,.xls"
                        onChange={handleFileChange}
                    />
                </label>
            </div>

            {file && (
                <button
                    onClick={handleFileUpload}
                    disabled={loading}
                    className="text-white linear rounded-xl border-2 border-white bg-green-500 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-green-500 hover:border-2 hover:border-green-500"
                >
                    {loading ? 'Uploading...' : 'Upload File'}
                </button>
            )}

            {loading && (
                <div className="flex justify-center items-center">
                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                        <span className="visually-hidden">
                            <FiLoader />
                        </span>
                    </div>
                </div>
            )}

            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                    {error}
                </div>
            )}

            {file && !error && (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
                    File selected successfully
                </div>
            )}
        </div>
    );
};
