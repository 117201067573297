import { React, useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { postOpenAdmission } from '../../redux/actions/admin/admission';
import { checkParentExist } from '../../redux/actions/admin/user';
import { getOpenSetting } from '../../redux/actions/admin/setting';
import { getOpenRecordFields } from '../../redux/actions/admin/fields';
import { useDispatch, useSelector } from 'react-redux';
import DialogCustom from '../../components/dialog';
import toast, { Toaster } from 'react-hot-toast';
import Form from '../../components/form';
import { getRecords } from '../../redux/actions/open';
import brandImg from "assets/img/logo.png";
import { ClipboardCopy, Check } from "lucide-react";

const notifySuccess = (message) => toast.success(message, {
    style: {
        padding: '35px',
        color: '#a0ca00',
    },
    duration: 5000,
    iconTheme: {
        primary: '#a0ca00',
        secondary: '#222c25',
    }
});

const notifyError = (message) => toast.error(message, {
    style: {
        border: '1px solid #fff',
        padding: '35px',
        color: 'red',
    },
    iconTheme: {
        primary: 'red',
        secondary: '#fff',
    }
});

const App = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        isOpenForm: true
    });
    const [done, setDone] = useState(false);
    const [existingParent, setExistingParent] = useState(null);
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [header, setHeader] = useState("Admission Form");
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const bId = searchParams.get('bid');

    const settings = useSelector((settings) => settings.setting.businessSettings);
    const customFields = useSelector((fields) => fields.field.value.singleRecordFields);

    const [classes, setClasses] = useState([]);
    const [batches, setBatches] = useState([]);
    const [courses, setCourses] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [translation, setTranslation] = useState([]);
    const [admissionFields, setAdmissionFields] = useState({
        personal: [
            {
                id: "name",
                name: "name",
                label: "Name",
                placeholder: "Enter Name",
                type: "text",
            },
            {
                id: "dateOfBirth",
                name: "dateOfBirth",
                label: "Date of Birth",
                placeholder: "Enter Date of Birth",
                type: "datetime",
                isSingle: true,
                isRange: false,
            },
            {
                id: "profile",
                name: "profile",
                label: "Profile Image",
                prevImage: "admission.profile",
                placeholder: "Click here to upload Your Profile Image",
                type: "upload",
            }
        ],
        parent: [
            {
                id: "parentName",
                name: "parentName",
                label: "Parent's Name",
                placeholder: "Enter Parent's Name",
                type: "text",
            },
            {
                id: "parentContact",
                name: "parentContact",
                label: "Parent's Contact",
                placeholder: "Enter Parent's Contact",
                type: "text",
            },
            {
                id: "parentEmail",
                name: "parentEmail",
                label: "Parent's Email",
                placeholder: "Enter Parent's Email",
                type: "mail",
            },
            {
                id: "address",
                name: "address",
                label: "Residential Address",
                prevImage: "admission.address",
                placeholder: "Enter your residential address",
                type: "textarea",
            },
        ],
        enrollment: [],
        additional: [],
    });

    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(enquiryNumber);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    // Handle parent contact changes
    useEffect(() => {
        const fetchParentData = async () => {
            try {
                if (formData.parentContact) {
                    const result = await dispatch(checkParentExist({ code, mobile: formData.parentContact }));
                    setExistingParent(result.payload);
                }
            } catch (error) {
                console.error("Error fetching parent data:", error);
            }
        };
        fetchParentData();
    }, [formData.parentContact, code, dispatch]);

    // Update form when existing parent is found
    useEffect(() => {
        if (existingParent) {
            notifySuccess(`Mobile Number Identified as ${existingParent.fullname}`);
            setFormData(prev => ({
                ...prev,
                parentName: existingParent.fullname,
                parentEmail: existingParent.email
            }));
        }
    }, [existingParent]);

    useEffect(() => {
        const applyTranslations = () => {
            if (translation.length) {
                const updatedAdmissionFields = { ...admissionFields };

                translation.forEach(trans => {
                    Object.keys(updatedAdmissionFields).forEach(section => {
                        const fieldIndex = updatedAdmissionFields[section].findIndex(field => field.name === trans.target);
                        if (fieldIndex !== -1) {
                            updatedAdmissionFields[section][fieldIndex].label = trans.translation;
                            updatedAdmissionFields[section][fieldIndex].placeholder = trans.label;
                        }
                    });
                });

                setAdmissionFields(updatedAdmissionFields);
            }
        };

        // Introduce a delay to ensure other form field processing is complete
        const delay = setTimeout(applyTranslations, 250);

        // Cleanup the timeout on component unmount or before re-running the effect
        return () => clearTimeout(delay);
    }, [translation, admissionFields]);



    // Load initial data and handle batch selection
    useEffect(() => {
        dispatch(getOpenSetting(code));
        dispatch(getOpenRecordFields({ code, record: "Admission" }));

        const fetchData = async () => {
            const [classData, batchData, courseData, sessionData, translationData] = await Promise.all([
                dispatch(getRecords({ code, limit: 50, record: "class" })),
                dispatch(getRecords({ code, limit: 50, record: "batch" })),
                dispatch(getRecords({ code, limit: 50, record: "course" })),
                dispatch(getRecords({ code, limit: 50, record: "session" })),
                dispatch(getRecords({ code, limit: 150, record: "translation" }))
            ]);

            setClasses(classData.payload);
            setBatches(batchData.payload);
            setCourses(courseData.payload);
            setSessions(sessionData.payload);
            setTranslation(translationData.payload.rows);

            if (bId && batchData.payload?.rows) {
                const batch = batchData.payload.rows.find(b => b.id === bId);
                if (batch) {
                    setSelectedBatch(batch);
                    setHeader(`Admission Form for Academic Year ${batch.name}`);
                    setFormData(prev => ({
                        ...prev,
                        batchId: bId
                    }));
                }
            }
        };

        fetchData();
    }, [dispatch, code, bId]);

    // Update enrollment fields based on settings and data
    useEffect(() => {
        if (settings?.config && (batches?.rows || courses?.rows || sessions?.rows || classes?.rows)) {
            setAdmissionFields(prev => ({
                ...prev,
                enrollment: [
                    ...(!bId ? [{
                        id: "batchId",
                        name: "batchId",
                        label: "Batch",
                        placeholder: "Select Batch",
                        type: "dropdown",
                        toSelect: "id",
                        list: batches.rows || [],
                        selectedvalue: "batch",
                    }] : []),
                    {
                        id: "courseId",
                        name: "courseId",
                        label: "Course",
                        placeholder: "Select Course",
                        type: "dropdown",
                        toSelect: "id",
                        list: courses.rows || [],
                        selectedvalue: "course",
                    },
                    ...(settings?.config?.maktabType === "Maktab" ? [
                        {
                            id: "sessionId",
                            name: "sessionId",
                            label: "Session",
                            placeholder: "Select Session",
                            type: "dropdown",
                            toSelect: "id",
                            list: sessions.rows || [],
                            selectedvalue: "session",
                        },
                        {
                            id: "classId",
                            name: "classId",
                            label: "Class",
                            placeholder: "Select Class",
                            type: "dropdown",
                            toSelect: "id",
                            list: classes.rows || [],
                            selectedvalue: "class"
                        }
                    ] : [])
                ]
            }));
        }
    }, [settings?.config, batches?.rows, courses?.rows, sessions?.rows, classes?.rows, bId]);

    // Update custom fields with govId check
    useEffect(() => {
        if (customFields?.length) {
            const additionalFields = [];
            let govIdField = null;

            customFields
                .slice()
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .forEach(field => {
                    const formattedField = {
                        id: field.name,
                        name: field.name,
                        label: field.name,
                        placeholder: field.name,
                        type: field.type,
                        required: field.required,
                        toSelect: "name",
                        list: field.values ? field.values.split(", ").map((option, index) => ({
                            id: index + 1,
                            name: option.trim()
                        })) : "",
                        selectedvalue: field.name
                    };

                    if (field.name === 'Government ID') {
                        govIdField = formattedField;
                    } else {
                        additionalFields.push(formattedField);
                    }
                });

            setAdmissionFields(prev => ({
                ...prev,
                personal: govIdField ? [...prev.personal, govIdField] : prev.personal,
                additional: additionalFields
            }));
        }
    }, [customFields]);

    // Check admission status
    useEffect(() => {
        if (settings?.config?.enableAdmission === false) {
            notifyError("Admissions have been disabled right now.");
        }
    }, [settings?.config?.enableAdmission]);

    const [enquiryNumber, setEnquiryNumber] = useState([])
    const saveSettings = async () => {
        const missingFields = validateRequiredFields();

        if (missingFields.length > 0) {
            notifyError(`Please fill in the following required fields: ${missingFields.join(', ')}`);
            return;
        }
        try {
            const response = await dispatch(postOpenAdmission({ code, formData, isOpenForm: true }));
            setEnquiryNumber(response.payload.admissionEnquiryNumber);
            notifySuccess("Admission Added");
            setDone(true);
        } catch (err) {
            notifyError(err.message);
        }
    };


    if (!settings?.config?.enableAdmission) {
        return (
            <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
                <div className="bg-white rounded-xl shadow-lg p-8 text-center max-w-lg">
                    <h1 className="text-2xl font-semibold text-red-600 mb-4">Admissions Disabled</h1>
                    <p className="text-gray-600">The admission process is currently disabled. Please check back later or contact the administration for more information.</p>
                </div>
                <p className='text-center py-1 mt-2'>
                    powered by <a className='text-brand-500' target='_blank' href='https://maktab.info/' > maktab.info </a>
                </p>
            </div>
        );
    }

    const validateRequiredFields = () => {
        const allFields = [
            ...admissionFields.personal,
            ...admissionFields.parent,
            ...admissionFields.enrollment,
            ...admissionFields.additional
        ];

        const missingFields = allFields
            .filter(field => field.required && !formData[field.name])
            .map(field => field.label);

        return missingFields;
    };

    return (
        <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
                <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                    <header className="text-center p-8 border-b border-gray-200">
                        <img
                            src={settings.profile ? `${process.env.REACT_APP_URL}images${settings.profile}` : brandImg}
                            alt=""
                            className="mx-auto h-24 w-fit rounded-xl shadow-lg object-cover"
                        />
                        <h1 className="mt-6 text-3xl font-bold text-gray-900">{settings?.name}</h1>
                        <h2 className="text-xl font-semibold mb-4 capitalize text-gray-900">
                            {settings.config.address || ""}
                        </h2>
                        <p className="mt-2 text-xl text-gray-600">{header}</p>
                    </header>

                    {!done ? (
                        <div className="p-8">
                            {Object.entries(admissionFields).map(([section, fields]) =>
                                fields.length > 0 && (
                                    <div key={section} className="mb-8 last:mb-0">
                                        <h2 className="text-xl font-semibold text-gray-900 mb-4 capitalize">
                                            {section} Details
                                        </h2>
                                        <div className="bg-gray-50 rounded-lg p-6">
                                            <Form
                                                fields={fields}
                                                formData={formData}
                                                onChange={setFormData}
                                                mainDivCss={section == "additional" ? "" : "grid grid-cols-1 md:grid-cols-2 gap-6"}
                                            />
                                        </div>
                                    </div>
                                )
                            )}

                            <div className="mt-8 flex justify-end">
                                <button
                                    type="button"
                                    onClick={saveSettings}
                                    className="px-6 py-3 bg-brand-700 text-white rounded-full font-medium hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 transition-colors"
                                >
                                    Submit Application
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center items-center p-6">
                            <div className="rounded-lg bg-green-50 p-6 shadow-lg max-w-md text-center">
                                <h3 className="text-2xl font-semibold text-green-800 mb-3">Application Submitted!</h3>
                                <p className="text-green-600 mb-3">
                                    Thank you for your submission. We will review your application and get back to you soon.
                                </p>
                                <div className="bg-white p-3 rounded-md border border-green-300 flex items-center justify-between">
                                    <span className="text-green-700 font-medium">Enquiry Number: {enquiryNumber}</span>
                                    <button
                                        onClick={handleCopy}
                                        className="ml-2 p-2 rounded-md bg-green-100 text-green-700 hover:bg-green-200 transition"
                                    >
                                        {copied ? <Check size={18} /> : <ClipboardCopy size={18} />}
                                    </button>
                                </div>
                                <p className="mt-4 text-green-700">
                                    Please contact{" "}
                                    <a
                                        className="text-brand-600 font-medium hover:underline"
                                        href={`tel:+${settings.config.adminMobile}`}
                                    >
                                        {settings.config.adminMobile}
                                    </a>{" "}
                                    for any queries.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <p className='text-center py-1 mt-2'>
                powered by <a className='text-brand-500' target='_blank' href='https://maktab.info/' > maktab.info </a>
            </p>
            <Toaster />
        </div>
    );
};

export default App;