import { React, useEffect, useState } from 'react';
import { Routes, Route, Link, useLocation, useNavigate } from "react-router-dom";
import { validateToken, getProfile } from '../../redux/actions/auth';
import { setActiveStudentRedux } from '../../redux/actions/auth';
import { fetchSettings } from '../../redux/actions/admin/setting';
import { updateFCMtoken } from "../../redux/actions/auth";
import { motion } from 'framer-motion';

import { useDispatch, useSelector } from 'react-redux'
import Home from '../../views/parent-staff/home';
import Enrollments from '../../views/parent-staff/enrollments';
import Notifications from '../../views/parent-staff/notifications';
import Course from '../../views/parent-staff/course';
import Classes from '../../views/parent-staff/classes';
import Profile from "../../views/parent-staff/profile";
import ResetPassword from "../../views/parent-staff/reset-password";
import DialogCustom from '../../components/dialog';
import toast, { Toaster } from 'react-hot-toast';
import Task from 'views/parent-staff/task';
import {
  FaHome,
  FaClipboardList,
  FaRegUser,
  FaBell,
  FaBook,
  FaTimes,
  FaBars
} from "react-icons/fa";
import { GoTasklist } from "react-icons/go";

import brandImg from "assets/img/logo.png";
import CrudAttendances from 'views/parent-staff/crud-attendance';
import Results from 'views/parent-staff/results';
import CrudLessonLogs from 'views/parent-staff/crud-lesson-log';
import { NotebookTabs } from 'lucide-react';

const notifySuccess = (message) => toast.success(message, {
  style: {
    padding: '35px',
    color: '#a0ca00',
  },
  duration: 2000,
  iconTheme: {
    primary: '#a0ca00',
    secondary: '#222c25',
  }
})
const notifyError = (message) => toast.error(message, {
  style: {
    border: '1px solid #fff',
    padding: '35px',
    color: 'red',
  },
  iconTheme: {
    primary: 'red',
    secondary: '#fff',
  }
})




const NavbarItem = ({ to, children, active }) => (
  <Link
    to={to}
    className={`p-5 m-1 mb-0 text-brand-500 text-xl flex justify-center text-center  transition-all duration-150 ease-in-out ${active ? ' shadow-gray-400   shadow-xl translate-y-[-35%] bg-brand-500 !text-white rounded-full' : ''
      }`}
  >
    {children}
  </Link>
);


const App = () => {
  // First, declare all useState hooks
  const [loading, setLoading] = useState(true);
  const [fcmToken, setFcmToken] = useState("");
  const [businessLogo, setBusinessLogo] = useState({});
  const [activeStudent, setActiveStudent] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [header, setHeader] = useState("Home");
  const [instituteName, setInstituteName] = useState(" Maktab ");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Then declare all other hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((user) => user.auth.value.profile);

  // Post message function
  const postMessage = (message) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
  };

  const onLoggedIn = async () => {
    postMessage({ isLoggedIn: true });
  };

  const fetchToken = async () => {
    postMessage({ fetchToken: true });
  };

  const onLoggedOut = async () => {
    postMessage({ isLoggedOut: true });
  };

  // Group all useEffects together
  useEffect(() => {
    fetchToken();
    const handleMessage = (event) => {
      if (event.data && event.data.type === 'token') {
        dispatch(updateFCMtoken({ fcmToken: event.data.token }));
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    dispatch(validateToken());
    window.scrollTo(0, 0);
    setHeader(location.pathname.split("/")[2].toUpperCase());
  }, [location.pathname, dispatch]);

  useEffect(() => {
    async function fetchData() {
      const action = await dispatch(fetchSettings());
      setBusinessLogo(action.payload.profile);
      setInstituteName(action.payload.name);
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    async function fetchData() {
      dispatch(getProfile());
      setLoading(false);
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (user && user.students) {
      setActiveStudent(user.students[0]);
      dispatch(setActiveStudentRedux(user.students[0]));
    }
    if (user?.role?.name === "Staff") setIsStaff(true);
  }, [user, dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col h-screen">
      <SidebarNav 
      isStaff={isStaff} 
      isOpen={isSidebarOpen} 
      onClose={() => setIsSidebarOpen(false)}
      logo={businessLogo}
      name={instituteName}
    />
      <div className="">
      <header className='m-3 rounded-xl shadow-xl p-3 bg-white flex justify-between items-center'>
        <SidebarToggle onToggle={() => setIsSidebarOpen(!isSidebarOpen)} />
        <h1 className='font-bold text-xl text-brand-500'> {header} </h1>
        <div 
          className='bg-brand-500 m-1 rounded-xl p-2' 
          onClick={() => setIsDialogOpen(true)}
        >
          {activeStudent?.admission?.profile ? (
            <img 
              src={`${process.env.REACT_APP_URL + "images" + activeStudent.admission.profile}`} 
              alt="" 
              className='w-10 h-10 object-cover rounded-full' 
            />
          ) : (
            <FaRegUser className='!text-white' />
          )}
        </div>
      </header>
        <div className='rounded-t-xl pb-20'>
          <Routes key={location.key}>
            <Route path="/home" element={<Home user={user} student={activeStudent} />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/tasks" element={<Task user={user} student={activeStudent} />} />
            <Route path="/classes" element={<Classes classes={user.classes} />} />
            <Route path="/enrollments" element={<Enrollments />} />
            <Route path="/course/:id" element={<Course />} />
            <Route path="/attendance/:id" element={<CrudAttendances />} />
            <Route path="/lessons" element={<CrudLessonLogs />} />
            <Route path="/results" element={<Results  student={activeStudent}/>} />
            <Route path="/add-lesson-log" element={<CrudLessonLogs />} />
          </Routes>
        </div>
      </div>
      <div className="shadow-gray-400 shadow-2xl fixed bg-white flex justify-around w-full" style={{ bottom: 0 }}>
        <NavbarItem styles="rounded-t-xl" to="/parent/home" active={location.pathname === '/parent/home'}>
          <FaHome />
        </NavbarItem>
        {!isStaff ? (
          <>
            <NavbarItem styles="rounded-t-xl" to="/parent/enrollments" active={location.pathname === '/parent/enrollments'}>
              <FaBook />
            </NavbarItem>
            <NavbarItem to="/parent/tasks" active={location.pathname === '/parent/tasks'}>
              <FaClipboardList />
            </NavbarItem>
          </>
        ) : (
          <>
            <NavbarItem styles="rounded-t-xl" to="/parent/classes" active={location.pathname === '/parent/classes' || location.pathname.includes("attendance")}>
              <GoTasklist />
            </NavbarItem>
            <NavbarItem styles="rounded-t-xl" to="/parent/lessons" active={location.pathname === '/parent/lessons' || location.pathname.includes("lessons")}>
              <NotebookTabs />
            </NavbarItem>
          </>
        )}
        <NavbarItem to="/parent/profile" active={location.pathname === '/parent/profile'}>
          <FaRegUser />
        </NavbarItem>
      </div>
      <Toaster />
      <DialogCustom
        open={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        dialogTitle="Select Student"
        dialogWidth="w-11/12"
        dialogDesc="Select a student to view their details"
        content={
          <>
            {user && user.students && user.students.map((student, index) => (
              <div
                key={student.id}
                onClick={() => {
                  setActiveStudent(student);
                  dispatch(setActiveStudentRedux(student));
                  setIsDialogOpen(false);
                  navigate("/parent/home");
                  notifySuccess(`Changed profile to ${student.admission.name}`);
                }}
                className={`mb-3 border-2 border-brand-500 flex justify-between p-3 rounded-xl shadow ${
                  student.id === activeStudent.id ? "!bg-brand-500 text-white" : ""
                }`}
              >
                <div className="profile">
                  <div className={`bg-brand-500 m-1 rounded-full ${
                    student.id === activeStudent.id ? "!bg-white" : ""
                  }`}>
                    {student?.admission?.profile ? (
                      <img 
                        src={`${process.env.REACT_APP_URL + "images" + student.admission.profile}`} 
                        alt="" 
                        className='w-10 h-10 object-cover rounded-full' 
                      />
                    ) : (
                      <FaRegUser className={`!text-white ${
                        student.id === activeStudent.id ? "!text-brand-500" : ""
                      }`} />
                    )}
                  </div>
                </div>
                <div className="name w-9/12 flex flex-col items-start ml-2">
                  <p className='font-semibold text-sm'>{student.admission.name}</p>
                  <p className='text-xs'>
                    {student.enrollments[0]?.course?.name + " - " + student.enrollments[0]?.class?.name}
                  </p>
                </div>
              </div>
            ))}
          </>
        }
      />
    </div>
  );
};

export default App;

const SidebarNav = ({ isStaff, isOpen, onClose, logo, name }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const NavItem = ({ to, icon: Icon, label }) => {
    const isActive = location.pathname === to;
    
    return (
      <Link
        to={to}
        onClick={onClose}
        className={`flex items-center gap-4 p-4 relative
          ${isActive ? 'text-white' : 'text-gray-600 hover:bg-brand-500/10'}`}
      >
        {isActive && (
          <motion.div
            layoutId="activeBackground"
            className="absolute inset-0 bg-brand-500 rounded-lg"
            initial={false}
            transition={{
              type: "spring",
              stiffness: 400,
              damping: 30
            }}
          />
        )}
        <span className="relative z-10">
          <Icon className="text-xl" />
        </span>
        <span className="relative z-10 transition-opacity duration-200">
          {label}
        </span>
      </Link>
    );
  };

  return (
    <>
      {/* Overlay */}
      {isOpen && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="fixed inset-0 bg-black/20 z-40"
          onClick={onClose}
        />
      )}

      {/* Sidebar */}
      <motion.div 
        initial={{ x: "-100%" }}
        animate={{ x: isOpen ? 0 : "-100%" }}
        transition={{ type: "spring", stiffness: 400, damping: 40 }}
        className="fixed top-0 left-0 h-full bg-white shadow-2xl z-50 w-64"
      >
        {/* Logo Section */}
        <div className="flex flex-col items-center p-6 border-b">
          <img
            className="w-20 rounded-xl mb-4 cursor-pointer"
            src={logo ? `${process.env.REACT_APP_URL + "images" + logo}` : brandImg}
            alt="Business Logo"
            onClick={() => { 
              navigate("/parent/home");
              onClose();
            }}
          />
          <p>{name || ""}</p>
          <button
            onClick={onClose}
            className="absolute top-4 right-4 p-2 hover:bg-gray-100 rounded-lg"
          >
            <FaTimes className="text-xl text-gray-600" />
          </button>
        </div>
        
        <div className="px-2 space-y-2 mt-4">
          <NavItem to="/parent/home" icon={FaHome} label="Home" />
          
          {!isStaff ? (
            <>
              <NavItem to="/parent/enrollments" icon={FaBook} label="Enrollments" />
              <NavItem to="/parent/tasks" icon={FaClipboardList} label="Tasks" />
              <NavItem to="/parent/results" icon={FaBell} label="Results" />
            </>
          ) : (
            <>
              <NavItem to="/parent/classes" icon={GoTasklist} label="Classes" />
              <NavItem to="/parent/lessons" icon={NotebookTabs} label="Lessons" />
            </>
          )}
          
          <NavItem to="/parent/profile" icon={FaRegUser} label="Profile" />
        </div>
      </motion.div>
    </>
  );
};

// Separate toggle button component
const SidebarToggle = ({ onToggle }) => (
  <button
    onClick={onToggle}
    className="p-2 hover:bg-brand-500/10 rounded-lg transition-colors duration-200"
  >
    <FaBars className="text-xl text-brand-500" />
  </button>
);