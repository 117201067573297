
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getTranslations = createAsyncThunk("translation/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/translation?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addTranslation = createAsyncThunk("translation/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/translation/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateTranslation = createAsyncThunk("translation/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/translation/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteTranslation = createAsyncThunk("translation/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/translation/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const translationsSlice = createSlice({
  name: 'translationes',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTranslations.fulfilled, (translation, action) => {
     
        translation.value.data.rows = action.payload.rows
        translation.value.data.count = action.payload.count
        translation.value.status = action.payload.message
        console.log('Updated translation:', translation.value.data.rows);

        
      })
      .addCase(getTranslations.pending, (translation) => {
        translation.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getTranslations.rejected, (translation) => {
        translation.status = "Failed to  fetch data..."
      })
      .addCase(updateTranslation.fulfilled, (translation, action) => {
        console.log(action.payload.message, "action.payload.message")
        translation.value.status = action.payload.message
        // find the index of the translation with the updated id
        // const index = translation.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the translation at the found index with the updated translation
        //   translation.value.data[index] = action.payload;
        // }
      })
      .addCase(updateTranslation.pending, (translation) => {
        translation.status = "Updating translation. Please wait a moment...";
      })
      .addCase(updateTranslation.rejected, (translation) => {
        translation.status = "Failed to update translation...";
      })
      .addCase(deleteTranslation.fulfilled, (translation, action) => {
        // // filter out the translation with the deleted id
        // translation.value.data = translation.value.data.filter(item => item.id !== action.payload.id);
        translation.value.status = action.payload.message
      })
      .addCase(deleteTranslation.pending, (translation) => {
        translation.status = "Deleting translation. Please wait a moment...";
      })
      .addCase(deleteTranslation.rejected, (translation) => {
        translation.status = "Failed to delete translation...";
      })
      .addCase(addTranslation.fulfilled, (translation, action) => {
        // translation.value.data.push(action.payload); // add the new translation to the list
     })
     .addCase(addTranslation.pending, (translation) => {
        translation.status = "Adding translation. Please wait a moment...";
     })
     .addCase(addTranslation.rejected, (translation) => {
        translation.status = "Failed to add translation...";
     });
  }
})

export default translationsSlice.reducer
