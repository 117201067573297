


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


export const getRecords = createAsyncThunk("records/get", async (query) => {
    console.log(query)

    let filteredQuery = Object.fromEntries(
      Object.entries(query).filter(([key, value]) => value != null && value !== '')
  );
  let params = new URLSearchParams(filteredQuery);

  let response = await fetch(`${process.env.REACT_APP_URL}admin/open/admission?${params.toString()}`, {
  });
  let json = await response.json();
  return json;
});


export const admissionSlice = createSlice({
  name: 'admission',
  initialState: {
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        activeCount: 0,
        inactiveCount: 0,
        rows: []
      }
    }
  },
  reducers: {

  },
})

export default admissionSlice.reducer
