import { React, useState } from 'react'
import SwitchField from '../fields/SwitchField'
import InputField from '../fields/InputField'
import TextField from '../fields/TextField'
import Upload from '../upload'
import DropdownSelect from '../../components/select'
import Datepicker from "react-tailwindcss-datepicker";
import moment from 'moment';
import Select from 'react-select'
import { useForm } from 'react-hook-form'
import toast, { Toaster } from 'react-hot-toast';

// Helper component for required field indicator
const RequiredIndicator = () => (
  <span className="text-red-500 ml-1">*</span>
);

export const Form = (props) => {
  const { fields, formData, onChange, mainDivCss, register, errors } = props
  const [value, setValue] = useState({
    startDate: null,
    endDate: null
  });
  const [selectedOptions, setSelectedOptions] = useState([]);

  const notifyError = (message) => toast.error(message, {
    style: {
      border: '1px solid #fff',
      padding: '35px',
      color: 'red',
    },
    iconTheme: {
      primary: 'red',
      secondary: '#fff',
    }
  });

  const validateField = (field, value) => {
    if (field.required && (value === undefined || value === null || value === '')) {
      notifyError(`${field.label} cannot be left empty`);
      return false;
    }
    return true;
  };

  const handleOptionChange = (fieldName, selected, field) => {
    if (validateField(field, selected)) {
      setSelectedOptions(selected);
      const newFormData = { ...formData, [fieldName]: selected };
      onChange(newFormData);
    }
  };

  const handleValueChange = (isSingle, fieldName, newValue, field) => {
    if (validateField(field, newValue)) {
      setValue(newValue);
      const newFormData = { ...formData, [fieldName]: isSingle ? newValue.startDate : newValue };
      onChange(newFormData);
    }
  };

  const handleFieldChange = (fieldName, value, type, field) => {
    if (!validateField(field, value)) {
      return;
    }

    if (type === "number") {
      const intValue = parseInt(value, 10);
      if (isNaN(intValue)) {
        return;
      }
      value = intValue;
    }

    const newFormData = { ...formData, [fieldName]: value };
    onChange(newFormData);
  };

  return (
    <div className={`${mainDivCss} m-2`}>
      <Toaster />
      {fields.map((field) => {
        let renderedField;
        switch (field.type) {
          case "checkbox":
            renderedField = (
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  id={field.id}
                  checked={formData[field.name] || false}
                  onChange={(e) => handleFieldChange(field.name, e.target.checked, null, field)}
                  className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer transition-colors duration-200 ease-in-out"
                />
                <div className="flex flex-col">
                  <label
                    htmlFor={field.id}
                    className="text-sm font-bold text-navy-700 cursor-pointer select-none"
                  >
                    {field.label}
                    {field.required ? <RequiredIndicator /> : ""}
                  </label>
                  {field.desc && (
                    <p className="text-xs text-gray-500">{field.desc}</p>
                  )}
                </div>
              </div>
            );
            break;
          case "switch":
            renderedField = (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  {field.label}
                  {field.required ? <RequiredIndicator /> : ""}
                </label>
                <SwitchField
                  key={field.id}
                  id={field.id}
                  desc={field.desc}
                  required={field.required}
                  checked={formData[field.name] || false}
                  onChange={(e) => handleFieldChange(field.name, e.target.checked, null, field)}
                />
              </div>
            );
            break;
          case "text":
          case "mail":
          case "number":
          case "password":
          case "time":
            renderedField = (
              <div>
                <label className="block text-sm font-bold text-navy-700">
                  {field.label}
                  {field.required ? <RequiredIndicator /> : ""}
                </label>
                <InputField
                  key={field.id}
                  id={field.id}
                  name={field.name}
                  type={field.type}
                  placeholder={field.placeholder}
                  value={formData[field.name] || ''}
                  onChange={(e) => handleFieldChange(field.name, e.target.value, field.type, field)}
                  register={register || null}
                  errors={errors || null}
                  required={field.required || false}
                  ref={field.ref}
                />
              </div>
            );
            break;
          case "textarea":
            renderedField = (
              <div>
                <label className="block text-sm font-bold text-navy-700">
                  {field.label}
                  {field.required ? <RequiredIndicator /> : ""}
                </label>
                <TextField
                  key={field.id}
                  id={field.id}
                  placeholder={field.placeholder}
                  columns={field.columns}
                  rows={field.rows}
                  required={field.required}
                  value={formData[field.name] || ''}
                  onChange={(e) => handleFieldChange(field.name, e.target.value, null, field)}
                />
              </div>
            );
            break;
          case "upload":
            renderedField = (
              <div>
                <label className="block text-sm font-bold text-navy-700">
                  {field.label || field.name}
                  {field.required ? <RequiredIndicator /> : ""}
                </label>
                <Upload
                  title={field.name}
                  prevData={formData[field.name]}
                  placeholder={field.placeholder}
                  required={field.required}
                  onChange={(base64Value) => handleFieldChange(field.name, base64Value, null, field)}
                />
              </div>
            );
            break;
          case "dropdown":
            renderedField = (
              <div>
                <label className="block text-sm font-bold text-navy-700">
                  {field.label}
                  {field.required ? <RequiredIndicator /> : ""}
                </label>
                <DropdownSelect
                  list={field.list}
                  placeHolder={field.placeHolder}
                  name={field.name}
                  required={field.required}
                  toSelect={field.toSelect}
                  selectedvalue={field.toShow ? formData[field.selectedvalue]?.[field.toShow] : (formData[field.selectedvalue]?.name ? formData[field.selectedvalue]?.name : formData[field.selectedvalue])}
                  onChange={(value) => handleFieldChange(field.id, value, null, field)}
                  toShow={field.toShow}
                  selectedObject={formData[field.selectedvalue]}
                />
              </div>
            );
            break;
          case "datetime":
          case "date":
            const value = moment(formData[field.name]).format('DD/MM/YYYY')
            renderedField = (
              <div>
                <label
                  htmlFor={field.id}
                  className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                >
                  {field.label}
                  {field.required ? <RequiredIndicator /> : ""}
                </label>
                <Datepicker
                  displayFormat={"DD/MM/YYYY"}
                  inputClassName="placeholder-gray-800 w-[100%] p-2 rounded-xl"
                  toggleClassName="text-navy-700 rounded-xl p-3"
                  primaryColor={"lime"}
                  containerClassName={"border-2 border-grey-400 rounded-xl flex mt-2"}
                  value={value}
                  asSingle={field.isSingle}
                  useRange={field.isRange}
                  placeholder={value ? value : formData["placeholder"]}
                  onChange={(e) => handleValueChange(field.isSingle, field.id, e, field)}
                />
              </div>
            );
            break;
          case "dateandtime":
            const formattedDate = formData[field.name] ? moment(formData[field.name]).format("YYYY-MM-DDTHH:mm") : "";
            renderedField = (
              <div className='flex flex-col'>
                <label
                  htmlFor={field.id}
                  className="ml-3 mb-2 text-sm font-bold text-navy-700 dark:text-white"
                >
                  {field.label}
                  {field.required ? <RequiredIndicator /> : ""}
                </label>
                <input
                  type="datetime-local"
                  className="appearance-none border border-grey-500 focus:border-brand-500 focus:outline-none focus:ring-brand-500 leading-tight px-4 py-3 rounded-xl"
                  value={formattedDate}
                  onChange={(e) => handleFieldChange(field.name, e.target.value, null, field)}
                />
              </div>
            );
            break;
          case "multiselect":
            function getValueByKeyArray(obj, keysArray) {
              if (!obj || !keysArray) return {}
              let value = obj;
              for (const key of keysArray) {
                if (value.hasOwnProperty(key)) {
                  value = value[key];
                } else {
                  return undefined;
                }
              }
              return value;
            }
            const mapOptionsToSelectOptions = (option) => ({
              value: getValueByKeyArray(option, field.toSelect),
              label: option.name,
            });
            const selectOptions = field.options.map(mapOptionsToSelectOptions);
            renderedField = (
              <div className='flex flex-col'>
                <label
                  htmlFor={field.id}
                  className="text-sm font-bold text-navy-700 dark:text-white"
                >
                  {field.label}
                  {field.required ? <RequiredIndicator /> : ""}
                </label>
                <Select
                  classNames={{
                    input: (state) =>
                      state.isFocused ? 'border-red-600' : 'border-grey-300',
                  }}
                  isMulti={true}
                  value={selectedOptions}
                  onChange={(e) => handleOptionChange(field.name, e, field)}
                  options={selectOptions}
                />
              </div>
            );
            break;
          default:
            renderedField = null;
        }

        return (
          <div key={field.id} className='my-5'>
            {renderedField}
          </div>
        );
      })}
    </div>
  )
}

export default Form