import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

export const Upload = (props) => {
    const { title, onChange, prevData, placeholder } = props;
    const [imagePreview, setImagePreview] = useState(null);
    const [base64, setBase64] = useState("");
    const MAX_FILE_SIZE = 1024 * 1024; // 1MB in bytes

    useEffect(() => {
        if (prevData && !imagePreview) {
            setImagePreview(process.env.REACT_APP_URL + "images" + prevData);
        }
    }, [prevData, imagePreview]);

    const handleDelete = () => {
        setImagePreview(null);
        onChange(""); // Clear the base64 data
    };

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop: files => {
            if (!files || files.length === 0) {
                return;
            }

            const file = files[0];
            
            // Check file size
            if (file.size > MAX_FILE_SIZE) {
                alert("File size exceeds 1MB limit. Please choose a smaller file.");
                return;
            }

            const newPreview = URL.createObjectURL(file);
            setImagePreview(newPreview);

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setBase64(reader.result);
                onChange(reader.result);
            };
            reader.onerror = error => console.log('Error converting file to base64:', error);
        },
        maxFiles: 1,
        accept: {
            'image/*': ['.png', '.jpg', '.jpeg', '.gif', '.webp']
        }
    });

    return (
        <section className="container w-full p-4 rounded-xl border-2 cursor-pointer">
            <p> {title || "Title"} </p>
            <div {...getRootProps({ className: 'dropzone text-center font-bold' })}>
                <input {...getInputProps()} />
                <p>{placeholder}</p>
                <p className="text-sm text-gray-500 mt-1">Maximum file size: 1MB</p>
            </div>
            <aside className='flex justify-center p-4'>
                {imagePreview && (
                    <div className="relative">
                        <img src={imagePreview} alt="Preview" className="w-32 h-32 object-cover rounded-xl border-2 shadow-xl mr-2 mb-2" />
                        <button 
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDelete();
                            }} 
                            className="absolute w-6 h-6 top-0 right-0 bg-red-500 text-white rounded-full cursor-pointer"
                        >
                            X
                        </button>
                    </div>
                )}
            </aside>
        </section>
    );
}

export default Upload;