
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getHalls = createAsyncThunk("hall/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/hall?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const getStudentsForHall = createAsyncThunk("getStudentsForHall/get", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/hall/students/${data.id}`, {
    method: 'GET',
    headers: createHeaders(),
  });
  const json = await response.json();
  return json;
});

export const bulkUpdateHall = createAsyncThunk("bulk-update/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/hall/bulk-update`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const removeFromHall = createAsyncThunk("remove-from-hall/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/hall/remove-student`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const addHall = createAsyncThunk("hall/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/hall/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateHall = createAsyncThunk("hall/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/hall/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteHall = createAsyncThunk("hall/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/hall/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const hallsSlice = createSlice({
  name: 'halles',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHalls.fulfilled, (hall, action) => {
     
        hall.value.data.rows = action.payload.rows
        hall.value.data.count = action.payload.count
        hall.value.status = action.payload.message
        console.log('Updated hall:', hall.value.data.rows);

        
      })
      .addCase(getHalls.pending, (hall) => {
        hall.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getHalls.rejected, (hall) => {
        hall.status = "Failed to  fetch data..."
      })
      .addCase(updateHall.fulfilled, (hall, action) => {
        console.log(action.payload.message, "action.payload.message")
        hall.value.status = action.payload.message
        // find the index of the hall with the updated id
        // const index = hall.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the hall at the found index with the updated hall
        //   hall.value.data[index] = action.payload;
        // }
      })
      .addCase(updateHall.pending, (hall) => {
        hall.status = "Updating hall. Please wait a moment...";
      })
      .addCase(updateHall.rejected, (hall) => {
        hall.status = "Failed to update hall...";
      })
      .addCase(deleteHall.fulfilled, (hall, action) => {
        // // filter out the hall with the deleted id
        // hall.value.data = hall.value.data.filter(item => item.id !== action.payload.id);
        hall.value.status = action.payload.message
      })
      .addCase(deleteHall.pending, (hall) => {
        hall.status = "Deleting hall. Please wait a moment...";
      })
      .addCase(deleteHall.rejected, (hall) => {
        hall.status = "Failed to delete hall...";
      })
      .addCase(addHall.fulfilled, (hall, action) => {
        // hall.value.data.push(action.payload); // add the new hall to the list
     })
     .addCase(addHall.pending, (hall) => {
        hall.status = "Adding hall. Please wait a moment...";
     })
     .addCase(addHall.rejected, (hall) => {
        hall.status = "Failed to add hall...";
     });
  }
})

export default hallsSlice.reducer
