import React, { useState, useEffect } from 'react';
import Form from "../../../components/form"
import { fetchSettings, updateSetting } from '../../../redux/actions/admin/setting';
import { useSelector, useDispatch } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';

const SettingsPage = () => {
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    useEffect(() => {
        console.log("get'ed settings");
        dispatch(fetchSettings())
        

    }, [dispatch])

    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })


    const saveSettings = async (data) => {
        try {
            console.log(settings, "SETTINGS");
            console.log(data, "DATA");

            if(data.shortName.length > 30) return  notifyError(" Short name can't be longer than 30 characters ");
            const updateSettings = await dispatch(updateSetting({ 
                ...data, 
                profile: data.profile, 
                id: settings.id,
                enableWhatsappNotifications: data.enableWhatsappNotifications || false,
                enableEmailNotifications: data.enableEmailNotifications || false 
            }));
            notifySuccess("Updated settings");
            dispatch(fetchSettings());
        } catch (err) { 
            notifyError(err.message);
        }
    }


    const settings = useSelector((settings) => settings.setting.value);
    // console.log(settings);
    
    useEffect(() => {
        
        setFormData(settings)
    }, [settings])
    
    useEffect(() => {
        console.log(formData);
    }, [formData])

    const fields = [
        {
            id: "name",
            name: "name",
            label: "Institutes Name",
            placeholder: "Enter Your Institutes's Name",
            type: "text",
        },
        {
            id: "address",
            name: "address",
            label: "Institutes Address",
            placeholder: "Enter Your Institutes's Address",
            type: "textarea",
        },
        {
            id: "profile",
            name: "profile",
            label: "Institutes Image",
            prevImage: settings.profile,
            placeholder: "Click here to upload Your Institutes's Image",
            type: "upload",
        },
        {
            id: "enableAdmission",
            name: "enableAdmission",
            label: "Admission Form",
            desc: "Enable Admission Form ?",
            type: "switch",
        },
        {
            id: "enableWhatsappNotifications",
            name: "enableWhatsappNotifications",
            label: "WhatsApp Notifications",
            desc: "Enable WhatsApp Notifications?",
            type: "switch",
        },
        {
            id: "enableEmailNotifications",
            name: "enableEmailNotifications",
            label: "Email Notifications",
            desc: "Enable Email Notifications?",
            type: "switch",
        },
        {
            id: "maktabType",
            name: "maktabType",
            label: "Set Institution Type",
            placeHolder: "Instution Type ",
            type: "dropdown",
            list: [
                { id: "Maktab", name: "Maktab" },
                { id: "Madrasa", name: "Madrasa" },
                { id: "Niswan", name: "Niswan" },
                { id: "Islamic School", name: "Islamic School" },
            ],
            toSelect: "id",
            selectedvalue: "maktabType",
            required: true,
            // disabled: !courses.rows.length
        },
        {
            id: "countryCode",
            name: "Country",
            label: "Set Origin Country",
            placeHolder: "Pick a country",
            type: "dropdown",
            list: [
                { name: "🇮🇳 India", id: 91 },
                { name: "🇺🇸 United States", id: 1 },
                { name: "🇬🇧 United Kingdom", id: 44 },
                { name: "🇨🇦 Canada", id: 1 },
                { name: "🇦🇺 Australia", id: 61 },
                { name: "🇳🇿 New Zealand", id: 64 },
                { name: "🇩🇪 Germany", id: 49 },
                { name: "🇫🇷 France", id: 33 },
                { name: "🇯🇵 Japan", id: 81 },
                { name: "🇨🇳 China", id: 86 },
                { name: "🇧🇷 Brazil", id: 55 },
                { name: "🇷🇺 Russia", id: 7 },
                { name: "🇿🇦 South Africa", id: 27 },
                { name: "🇰🇷 South Korea", id: 82 },
                { name: "🇮🇹 Italy", id: 39 },
                { name: "🇲🇽 Mexico", id: 52 },
                { name: "🇪🇸 Spain", id: 34 },
                { name: "🇸🇦 Saudi Arabia", id: 966 },
                { name: "🇦🇪 United Arab Emirates", id: 971 },
                { name: "🇸🇬 Singapore", id: 65 },
                { name: "🇳🇬 Nigeria", id: 234 },
                { name: "🇹🇷 Turkey", id: 90 },
                { name: "🇵🇰 Pakistan", id: 92 },
                { name: "🇧🇩 Bangladesh", id: 880 },
                { name: "🇲🇾 Malaysia", id: 60 },
                { name: "🇮🇩 Indonesia", id: 62 },
                { name: "🇶🇦 Qatar", id: 974 },
                { name: "🇰🇼 Kuwait", id: 965 },
                { name: "🇧🇭 Bahrain", id: 973 },
                { name: "🇴🇲 Oman", id: 968 },
                { name: "🇪🇬 Egypt", id: 20 },
                { name: "🇮🇷 Iran", id: 98 },
                { name: "🇮🇶 Iraq", id: 964 },
                { name: "🇦🇫 Afghanistan", id: 93 },
              ],
            toSelect: "id",
            selectedvalue: "countryCode",
            required: true,
            // disabled: !courses.rows.length
        },
        {
            id: "whatsappLanguage",
            name: "Whatsapp Language",
            label: "Set WhatsApp Language",
            placeHolder: "Pick a Language",
            type: "dropdown",
            list: [
                { name: "English", id: "en" },
                { name: "Tamil", id: "ta" }
              ],
            toSelect: "id",
            selectedvalue: "whatsappLanguage",
            required: true,
            // disabled: !courses.rows.length
        },
        {
            id: "adminMobile",
            name: "adminMobile",
            label: "Admin Mobile Number",
            placeholder: "Enter official contact number",
            type: "number",
        },
        {
            id: "shortName",
            name: "shortName",
            label: "Short Name",
            placeholder: "Enter short name for WhatsApp messages (max 30 characters)",
            type: "text",
        }
    ];

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                <Form
                    fields={fields}
                    formData={formData}
                    onChange={(newFormData) => setFormData(newFormData)}
                />
                <div className='flex justify-end'>
                    <button type="button"
                        onClick={() => saveSettings(formData)}
                        className="w-1/6 border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70"> Save </button>
                </div>
            </div>

        </div>
    );
};

export default SettingsPage;
