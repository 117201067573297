import Tabs from "../../../components/tabs";
import { getStudentGroups, getStudentsForGroup, bulkUpdateGroup, removeFromGroup } from "../../../redux/actions/admin/studentgroup";
import Widget from "components/widget/Widget";
import { FiUser, FiUsers } from "react-icons/fi";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEnrollments } from '../../../redux/actions/admin/enrollment';
import { getBatches } from "../../../redux/actions/admin/batch";
import { getCourses } from '../../../redux/actions/admin/course';
import DropdownSelect from 'components/select';
import toast from 'react-hot-toast';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";
import { useParams } from 'react-router-dom';


const Enrollment = ({ setTitle }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [studentGroup, setStudentGroup] = useState({});
    const [students, setStudents] = useState([]);
    useEffect(() => {
        dispatch(getStudentGroups({ id })).then((data) => {
            setStudentGroup(data.payload.rows[0]);
            setTitle("Student Group / " + data?.payload?.rows[0]?.course?.name + " - " + data?.payload?.rows[0]?.subject?.name);
        });
        dispatch(getStudentsForGroup({id})).then((data) => {
            setStudents(data.payload);
        });
    }, [dispatch]);

    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })

    const refresh = () => {
        dispatch(getStudentGroups({ id })).then((data) => {
            setStudentGroup(data.payload.rows[0]);
            setTitle("Student Group / " + data?.payload?.rows[0]?.course?.name + " - " + data?.payload?.rows[0]?.subject?.name);
        });
        dispatch(getStudentsForGroup({id})).then((data) => {
            setStudents(data.payload);
        });
        notifySuccess("success")
    }

    
    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <div className="h-full w-full rounded-xl">
                <div className="my-4 grid grid-cols-2 gap-5 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-1">
                    {/* <Widget
                        icon={<FiUser className="h-7 w-7" />}
                        title={"Total Students in Course"}
                        subtitle={studentGroup?.user?.fullname}
                    /> */}
                    <Widget
                        icon={<FiUsers className="h-7 w-7" />}
                        title={"Total Students"}
                        subtitle={studentGroup?.studentgroupinfos?.length}
                    />
                </div>
                <Tabs
                    tabs={["Students", "Assign Students"]}
                    tabContents={[<Students students={students} refresh={refresh} />, <Assign  refresh={refresh}/>]}
                />
            </div>
        </div>
    );
};

export default Enrollment;


const Assign = ( props ) => {
    const {
        refresh
    } = props
    const dispatch = useDispatch();
    const { id } = useParams();
    const [filter, setFilter] = useState({ sessionId: '', courseId: '', limit: 200 });
    const [selectedStudents, setSelectedStudents] = useState([]);
    const enrollments = useSelector((state) => state.enrollment.value.data);
    const batches = useSelector((batches) => batches.batch.value.data);
    const courses = useSelector((state) => state.course.value.data);

    useEffect(() => {
        dispatch(getBatches({ limit: 50 }));
        dispatch(getCourses({ limit: 50 }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getEnrollments(filter));
    }, [dispatch, filter]);

    const handleAssign = () => {
        const enrollmentIds = selectedStudents.map(enrollmentId =>
            enrollments.rows.find(enrollment => enrollment.id === enrollmentId).id
        );
        // console.log('Enrollment IDs:', enrollmentIds);
        // console.log('Class ID:', id);
        dispatch(bulkUpdateGroup( 
            { 
                enrollments: enrollmentIds,
                studentgroupId: id
             } 
        ))
        refresh()
        toast.success('Students assigned successfully');
        setSelectedStudents([]);
        setFilter({ sessionId: '', courseId: '' }); // Clear filters
    };

    return (
        <div>
            <Table>
                <TableCaption>
                    <div className="filters flex justify-between items-center">
                        <div className="flex items-center">
                            <DropdownSelect
                                list={batches.rows.map(batch => ({ name: batch.name, id: batch.id }))}
                                triggerStyles="bg-lightPrimary !rounded-full !w-[200px] !mr-4"
                                placeHolder="Select Batch"
                                toSelect="id"
                                onChange={(value) => setFilter({ ...filter, batchId: value })}
                            />
                            <DropdownSelect
                                list={courses.rows.map(course => ({ name: course.name, id: course.id }))}
                                triggerStyles="bg-lightPrimary !rounded-full !w-[200px] ml-4"
                                placeHolder="Select Course"
                                toSelect="id"
                                onChange={(value) => setFilter({ ...filter, courseId: value })}
                            />
                        </div>
                        <button
                            onClick={handleAssign}
                            className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70">
                            Assign Students
                        </button>
                    </div>
                    <div className="selected-students flex flex-wrap mt-4">
                        {selectedStudents.map((enrollmentId) => {
                            const student = enrollments.rows.find(enrollment => enrollment.id === enrollmentId);
                            return (
                                <span key={enrollmentId} className="bg-gray-200 text-gray-700 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">
                                    {student?.student?.admission?.name || 'N/A'}
                                </span>
                            );
                        })}
                    </div>
                </TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead>Select</TableHead>
                        <TableHead>Student Name</TableHead>
                        <TableHead>Register Number</TableHead>
                        <TableHead>Course</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {enrollments?.rows?.map((enrollment) => (
                        <TableRow key={enrollment.id}>
                            <TableCell>
                                <input
                                    type="checkbox"
                                    checked={selectedStudents.includes(enrollment.id)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedStudents([...selectedStudents, enrollment.id]);
                                        } else {
                                            setSelectedStudents(selectedStudents.filter(id => id !== enrollment.id));
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell>{enrollment.student?.admission?.name || 'N/A'}</TableCell>
                            <TableCell>{enrollment.registerNumber || 'N/A'}</TableCell>
                            <TableCell>{enrollment.course?.name || 'N/A'}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

const Students = (props) => {
    const {
        refresh
    } = props
    const dispatch = useDispatch();
    const { id } = useParams();
    const enrollments = props.students

    return (
        <div>
            {
                enrollments?.length === 0 &&
                <div className="text-center text-lg text-gray-500 py-4"> No students found </div>
            }
            {
                enrollments?.length > 0 &&
                <Table>
                    <TableCaption>Students</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Student Name</TableHead>
                            <TableHead>Register Number</TableHead>
                            {/* <TableHead>Course</TableHead> */}
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {enrollments?.map((enrollment) => (
                            <TableRow key={enrollment.id}>
                                <TableCell>{enrollment.student?.admission?.name || 'N/A'}</TableCell>
                                <TableCell>{enrollment.registerNumber || 'N/A'}</TableCell>
                                {/* <TableCell>{enrollment.course?.name || 'N/A'}</TableCell> */}
                                <TableCell className="w-1/12">
                                    <button
                                        onClick={() => {
                                            dispatch(removeFromGroup({ enrollmentId: enrollment.id }));
                                            dispatch(getEnrollments({ limit: 100, classId: id }));
                                            refresh()
                                            toast.success('Student removed successfully');
                                        }}
                                        title='Remove Student From Class'
                                        className="border-2 text-white linear rounded-xl bg-red-500 px-2 py-1 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Remove
                                    </button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }
        </div>
    );         
};