import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getStudentDashboardData } from '../../../redux/actions/admin/dashboard'
import { publishSingleExamResult } from '../../../redux/actions/admin/examset'
import { useParams } from 'react-router-dom'
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { AlertCircle, BookOpen, Calendar, GraduationCap, Globe, School, User, Heart, FileSliders, Send } from 'lucide-react';
import { FaTasks } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';


const Dashboard = (props) => {
    const {
        setTitle
    } = props
    const [data, setData] = useState(null)
    const { id } = useParams();
    console.log(id, "STUDENT ID")
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getStudentDashboardData({ id })).then((data) => {
            console.log(data.payload, "STUDENT DASH DATA")
            setData(data.payload)
        })
    }, [dispatch])


    setTitle("Student Dashboard")
    return (
        data && <DashboardUI data={data} />
    )
}

const notifySuccess = (message) => toast.success(message, {
    style: {
        padding: '35px',
        color: '#a0ca00',
    },
    duration: 3000,
    iconTheme: {
        primary: '#a0ca00',
        secondary: '#222c25',
    }
})
const notifyError = (message) => toast.error(message, {
    style: {
        border: '1px solid #fff',
        padding: '35px',
        color: 'red',
    },
    iconTheme: {
        primary: 'red',
        secondary: '#fff',
    }
})



const DashboardUI = ({ data }) => {
    const { studentDetails, courses, attendanceData, completedTasks, weeklyAttendance } = data;
    const [selectedCourseIndex, setSelectedCourseIndex] = useState(0);
    const dispatch = useDispatch()
    const { id } = useParams();
    const totalPresent = attendanceData.reduce((sum, month) => sum + month.presentCount, 0);
    const totalAbsent = attendanceData.reduce((sum, month) => sum + month.absentCount, 0);
    const attendancePercentage = totalPresent + totalAbsent === 0
        ? 0
        : ((totalPresent / (totalPresent + totalAbsent)) * 100).toFixed(1);

    const currentCourse = courses[selectedCourseIndex];
    const averageScore = currentCourse.averageScore || 0; // Default to 0 if undefined
    const completedExams = currentCourse.completedExams || 0; // Default to 0 if undefined

    const admission = studentDetails.admission;
    const handleSendToParent = (examset) => {
        // This function will be implemented later to handle sending results to parent
        console.log(`Sending results for examset ${examset.name} to parent`);
        dispatch(publishSingleExamResult({
            examset,
            studentId: id
        }))
        notifySuccess("Result Sent")
    
    };
    return (
        <div className="mt-3 min-h-[84vh] w-full space-y-6 rounded-xl bg-gradient-to-br from-gray-50 to-white p-6 dark:from-navy-800 dark:to-navy-700">
            {/* Header with Course Selection */}
            <div className="flex flex-col items-start justify-between space-y-4 md:flex-row md:items-center md:space-y-0">
                <div>
                    <h1 className="text-2xl font-bold text-gray-800">Student Profile</h1>
                    <p className="text-gray-500">Manage and track student progress</p>
                </div>
                {/* <select 
            className="w-full rounded-lg border border-gray-200 bg-white px-4 py-2 shadow-sm transition-all hover:border-blue-500 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200 md:w-64"
            value={selectedCourseIndex}
            onChange={(e) => setSelectedCourseIndex(Number(e.target.value))}
          >
            {courses.map((course, index) => (
              <option key={course.courseId} value={index}>
                {course.courseName}
              </option>
            ))}
          </select> */}
            </div>
            <Toaster />
            {/* Student Details Card */}
            <div className="overflow-hidden rounded-xl bg-white shadow-lg">
                <div className="border-b border-gray-100 bg-gradient-to-r from-blue-50 to-indigo-50 p-6">
                    <h2 className="text-xl font-bold text-gray-800">Student Information</h2>
                </div>
                <div className="grid grid-cols-1 gap-6 p-6 lg:grid-cols-2">
                    {/* Personal Details */}
                    <div className="space-y-6">
                        <h3 className="flex items-center text-lg font-semibold text-gray-700">
                            <User className="mr-2 h-5 w-5" />
                            Personal Details
                        </h3>
                        <div className="grid grid-cols-2 gap-6">
                            <div className="space-y-1">
                                <p className="text-sm font-medium text-gray-500">Full Name</p>
                                <p className="text-gray-800">{admission.name || 'Not Provided'}</p>
                            </div>
                            <div className="space-y-1">
                                <p className="text-sm font-medium text-gray-500">Date of Birth</p>
                                <p className="text-gray-800">{admission.dateOfBirth || 'Not Provided'}</p>
                            </div>
                            <div className="space-y-1">
                                <p className="text-sm font-medium text-gray-500"> Address </p>
                                <p className="text-gray-800">{admission.address || 'Not Provided'}</p>
                            </div>

                            {/*<div className="space-y-1">
                  <p className="text-sm font-medium text-gray-500">Religion</p>
                  <p className="text-gray-800">{admission.religion || 'Not Provided'}</p>
                </div> */}
                        </div>
                        {/* <div className="space-y-1">
                <p className="text-sm font-medium text-gray-500">Previous School</p>
                <p className="text-gray-800">{admission.previousSchool || 'Not Provided'}</p>
              </div> */}
                    </div>
                    <div>
                        <h3 className="mb-4 flex items-center text-lg font-semibold text-gray-700">
                            <User className="mr-2 h-5 w-5" />
                            Parent Details
                        </h3>
                        <div className="grid gap-4">
                            <div className="grid grid-cols-2 gap-6">
                                <div className="space-y-1">
                                    <p className="text-sm font-medium text-gray-500">Parent Name</p>
                                    <p className="text-gray-800">{admission.parentName}</p>
                                </div>
                                <div className="space-y-1">
                                    <p className="text-sm font-medium text-gray-500">Contact</p>
                                    <p className="text-gray-800">{admission.parentContact}</p>
                                </div>
                            </div>
                            <div className="space-y-1">
                                <p className="text-sm font-medium text-gray-500">Email</p>
                                <p className="text-gray-800">{admission.parentEmail}</p>
                            </div>
                        </div>
                    </div>

                    {/* Medical & Parent Details */}
                    <div className="space-y-6">
                        <div>
                            <h3 className="mb-4 flex items-center text-lg font-semibold text-gray-700">
                                <FileSliders className="mr-2 h-5 w-5" />
                                Custom Fields
                            </h3>
                            <div className="grid grid-cols-2 gap-6">
                                {admission?.customfields &&
                                    Object.entries(admission.customfields).map(([key, value]) => (
                                        <div className="space-y-1" key={key}>
                                            <p className="text-sm font-medium text-gray-500">{key}</p>
                                            <p className="text-gray-800">{value || "Not Provided"}</p>
                                        </div>
                                    ))}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
                <div className="group rounded-xl bg-white p-6 shadow-lg transition-all hover:-translate-y-1 hover:shadow-xl">
                    <div className="flex items-center">
                        <div className="rounded-full bg-gradient-to-r from-green-500 to-green-600 p-3 shadow-lg group-hover:scale-110">
                            <Calendar className="h-6 w-6 text-white" />
                        </div>
                        <div className="ml-4">
                            <p className="text-sm font-medium text-gray-500">Attendance</p>
                            <h3 className="text-lg font-bold text-gray-800">{attendancePercentage}%</h3>
                        </div>
                    </div>
                </div>

                <div className="group rounded-xl bg-white p-6 shadow-lg transition-all hover:-translate-y-1 hover:shadow-xl">
                    <div className="flex items-center">
                        <div className="rounded-full bg-gradient-to-r from-amber-500 to-amber-600 p-3 shadow-lg group-hover:scale-110">
                            <FaTasks className="h-6 w-6 text-white" />
                        </div>
                        <div className="ml-4">
                            <p className="text-sm font-medium text-gray-500"> Tasks Completed </p>
                            <h3 className="text-lg font-bold text-gray-800">{completedTasks}</h3>
                        </div>
                    </div>
                </div>

                <div className="group rounded-xl bg-white p-6 shadow-lg transition-all hover:-translate-y-1 hover:shadow-xl">
                    <div className="flex items-center">
                        <div className="rounded-full bg-gradient-to-r from-purple-500 to-purple-600 p-3 shadow-lg group-hover:scale-110">
                            <BookOpen className="h-6 w-6 text-white" />
                        </div>
                        <div className="ml-4">
                            <p className="text-sm font-medium text-gray-500"> Total Enrollments </p>
                            <h3 className="text-lg font-bold text-gray-800">{courses?.length || 0}</h3>
                        </div>
                    </div>
                </div>
            </div>

            {/* Current Course Section */}
            <div className="rounded-2xl bg-white p-8 shadow-xl flex flex-col space-y-8">
                <div className="flex items-center justify-between">
                    <div>
                        <h2 className="text-2xl font-extrabold text-gray-900">Course Details</h2>
                        <p className="text-sm text-gray-600">Currently enrolled in {currentCourse.courseName}</p>
                    </div>
                    <select
                        className="rounded-lg border border-gray-300 bg-white px-5 py-3 shadow-sm transition-all hover:border-blue-500 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200 md:w-72"
                        value={selectedCourseIndex}
                        onChange={(e) => setSelectedCourseIndex(Number(e.target.value))}
                    >
                        {courses.map((course, index) => (
                            <option key={course.courseId} value={index}>
                                {course.courseName}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Course Stats */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="rounded-xl border border-gray-200 p-6 shadow-md">
                        <div className="flex items-center">
                            <div className="rounded-full bg-gradient-to-r from-blue-600 to-blue-700 p-4 shadow-lg mr-4">
                                <GraduationCap className="h-6 w-6 text-white" />
                            </div>
                            <div>
                                <p className="text-base font-medium text-gray-600">Total Subjects</p>
                                <h3 className="text-2xl font-extrabold text-gray-900">{currentCourse.subjects.length}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-xl border border-gray-200 p-6 shadow-md">
                        <div className="flex items-center">
                            <div className="rounded-full bg-gradient-to-r from-green-600 to-green-700 p-4 shadow-lg mr-4">
                                <FileSliders className="h-6 w-6 text-white" />
                            </div>
                            <div>
                                <p className="text-base font-medium text-gray-600">Total Examsets</p>
                                <h3 className="text-2xl font-extrabold text-gray-900">{currentCourse.examsets?.length || 0}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-xl border border-gray-200 p-6 shadow-md">
                        <div className="flex items-center">
                            <div className="rounded-full bg-gradient-to-r from-purple-600 to-purple-700 p-4 shadow-lg mr-4">
                                <BookOpen className="h-6 w-6 text-white" />
                            </div>
                            <div>
                                <p className="text-base font-medium text-gray-600">Average Score</p>
                                <h3 className="text-2xl font-extrabold text-gray-900">{currentCourse.averageScore || 0}%</h3>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Examsets Section */}
                <div className="space-y-4">
                    <h3 className="text-xl font-bold text-gray-800"> Exams </h3>
                    <div className="grid gap-4">
                        {currentCourse.examsets?.map((examset) => (
                            <div
                                key={examset.id}
                                className="flex items-center justify-between rounded-xl bg-gradient-to-r from-gray-50 to-white p-6 border border-gray-200 shadow-md hover:border-blue-200 hover:from-blue-50 hover:to-white transition-all"
                            >
                                <div className="space-y-1">
                                    <h4 className="text-lg font-semibold text-gray-900">{examset.name}</h4>
                                    <div className="flex items-center gap-4">
                                        {/* <p className="text-sm text-gray-600">
                                            Score: <span className="font-medium">{examset.score || 'Not attempted'}</span>
                                        </p>
                                        {examset.date && (
                                            <p className="text-sm text-gray-600">
                                                Date: <span className="font-medium">{examset.date}</span>
                                            </p>
                                        )} */}
                                    </div>
                                </div>
                                <button
                                    onClick={() => handleSendToParent(examset)}
                                    className="flex items-center gap-2 rounded-lg bg-blue-50 px-4 py-2 text-sm font-medium text-blue-600 hover:bg-blue-100 transition-colors"
                                >
                                    <Send className="h-4 w-4" />
                                    Send to Parent
                                </button>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Subjects Section */}
                <div className="space-y-4">
                    <h3 className="text-xl font-bold text-gray-800">Subjects</h3>
                    <div className="space-y-4">
                        {currentCourse.subjects.map((subject) => (
                            <div
                                key={subject.subjectId}
                                className="flex items-center justify-between rounded-xl border border-gray-200 bg-gradient-to-r from-gray-100 to-white p-6 transition-all hover:border-blue-200 hover:from-blue-100 hover:to-white shadow-md"
                            >
                                <div>
                                    <h4 className="text-lg font-semibold text-gray-900">{subject.subjectName}</h4>
                                    <p className="text-sm text-gray-600">Total Exams: {subject.totalExams}</p>
                                </div>
                                <div className="text-right">
                                    <p className="text-sm text-gray-600">Average Score</p>
                                    <p className="text-lg font-extrabold text-gray-900">{subject.averageScore}%</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Monthly Attendance Chart - Full Width */}
            <div className="rounded-xl bg-white p-6 shadow-lg">
                <h2 className="mb-6 text-lg font-bold text-gray-800">Monthly Attendance Overview</h2>
                <div className="h-[400px]"> {/* Increased height for better visibility */}
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={attendanceData}>
                            <XAxis
                                dataKey="month"
                                stroke="#64748b"
                                fontSize={12}
                                tickLine={false}
                            />
                            <YAxis
                                stroke="#64748b"
                                fontSize={12}
                                tickLine={false}
                                axisLine={false}
                                tickFormatter={(value) => `${value}%`}
                            />
                            <Tooltip
                                contentStyle={{
                                    backgroundColor: 'white',
                                    border: '1px solid #e2e8f0',
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                }}
                            />
                            <Bar
                                dataKey="presentCount"
                                fill="#4CAF50"
                                name="Present"
                                radius={[4, 4, 0, 0]}
                            />
                            <Bar
                                dataKey="absentCount"
                                fill="#FF5252"
                                name="Absent"
                                radius={[4, 4, 0, 0]}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>

            {/* Weekly Attendance */}
            <div className="rounded-xl bg-white p-6 shadow-lg">
                <h2 className="mb-6 text-lg font-bold text-gray-800">This Week's Attendance</h2>
                <div className="grid grid-cols-7 gap-3">
                    {weeklyAttendance.map((day) => (
                        <div
                            key={day.date}
                            className={`flex flex-col items-center rounded-lg p-4 transition-all hover:scale-105 ${day.totalPresentCount > 0
                                ? 'bg-gradient-to-br from-green-100 to-green-50 text-green-800'
                                : day.totalAbsentCount > 0
                                    ? 'bg-gradient-to-br from-red-100 to-red-50 text-red-800'
                                    : 'bg-gradient-to-br from-gray-100 to-gray-50 text-gray-800'
                                }`}
                        >
                            <span className="text-sm font-medium">{day.day}</span>
                            <span className="mt-1 text-xs opacity-75">
                                {day.totalPresentCount > 0 ? 'Present' : day.totalAbsentCount > 0 ? 'Absent' : '-'}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};




export default Dashboard