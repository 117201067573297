import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStudentResults } from '../../redux/actions/home';
import { IoEnter, IoArrowBack } from "react-icons/io5";
import { motion, AnimatePresence } from 'framer-motion';

const Results = ({ student }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedExamSet, setSelectedExamSet] = useState(null);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (student && student.id) {
      dispatch(fetchStudentResults(student.id)).then((data) => {
        setResults(data.payload.results);
      });
    }
  }, [dispatch, student]);

  const ResultsList = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className="grid gap-4"
    >
      {results.map((examSet, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: index * 0.1 }}
          whileHover={{ scale: 1.02 }}
          onClick={() => setSelectedExamSet(examSet)}
          className="group relative bg-white border border-brand-500 hover:border-brand-500 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 p-4 cursor-pointer"
        >
          <div className="flex items-center justify-between">
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <h2 className="text-lg font-semibold text-gray-800">
                  {examSet.examsetName}
                </h2>
                <span className="px-2 py-1 text-xs font-medium bg-brand-500 bg-opacity-10 text-brand-500 rounded-full">
                  {examSet.courseName}
                </span>
              </div>
              
              <div className="space-y-1">
                <p className="text-sm text-gray-600 flex items-center">
                  <span className="w-20 text-gray-500">Total Exams:</span>
                  {examSet.examResults.length}
                </p>
                <p className="text-sm text-gray-600 flex items-center">
                  <span className="w-20 text-gray-500">Pass Rate:</span>
                  {Math.round((examSet.examResults.filter(exam => exam.obtainedScore >= exam.minScore).length / examSet.examResults.length) * 100)}%
                </p>
              </div>
            </div>

            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
              <div className="bg-brand-500 p-3 text-white rounded-full shadow-lg">
                <IoEnter className="text-2xl" />
              </div>
            </motion.div>
          </div>
        </motion.div>
      ))}
    </motion.div>
  );

  const ResultsDetail = ({ examSet }) => (
    <motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.3 }}
      className="space-y-4"
    >
      <motion.div 
        whileHover={{ scale: 1.02 }}
        onClick={() => setSelectedExamSet(null)}
        className="group relative bg-white border border-brand-500 hover:border-brand-500 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 p-4 cursor-pointer"
      >
        <div className="flex items-center space-x-2">
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            <div className="bg-brand-500 p-2 text-white rounded-full shadow-lg">
              <IoArrowBack className="text-xl" />
            </div>
          </motion.div>
          <h2 className="text-lg font-semibold text-gray-800">Back to Results</h2>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        className="bg-white rounded-xl shadow-lg p-4"
      >
        <div className="flex items-center space-x-2 mb-4">
          <h2 className="text-lg font-semibold text-gray-800">
            {examSet.examsetName}
          </h2>
          <span className="px-2 py-1 text-xs font-medium bg-brand-500 bg-opacity-10 text-brand-500 rounded-full">
            {examSet.courseName}
          </span>
        </div>

        <div className="space-y-4">
          {examSet.examResults.map((exam, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.3 + index * 0.1 }}
              className="border border-gray-100 rounded-lg p-4"
            >
              <div className="flex items-center justify-between">
                <div className="space-y-2">
                  <div className="flex items-center space-x-2">
                    <h3 className="text-md font-semibold text-gray-800">
                      {exam.examName}
                    </h3>
                  </div>

                  <div className="space-y-1">
                    <p className="text-sm text-gray-600 flex items-center">
                      <span className="w-20 text-gray-500">Score:</span>
                      <span className={exam.obtainedScore >= exam.minScore ? "text-brand-500" : "text-red-500"}>
                        {exam.obtainedScore}/{exam.totalScore}
                      </span>
                    </p>
                    <p className="text-sm text-gray-600 flex items-center">
                      <span className="w-20 text-gray-500">Min Score:</span>
                      {exam.minScore}
                    </p>
                    {exam.remarks && (
                      <p className="text-sm text-gray-600 flex items-center">
                        <span className="w-20 text-gray-500">Remarks:</span>
                        {exam.remarks}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </motion.div>
  );

  return (
    <div className="min-h-screen bg-lightPrimary p-4">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-2xl shadow-lg p-2 space-y-4">
          <AnimatePresence mode="wait">
            {results.length === 0 ? (
              <motion.div
                key="no-results"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex flex-col items-center justify-center py-12"
              >
                <div className="text-gray-400 text-lg mb-2">No results found</div>
                <p className="text-sm text-gray-500">Complete some exams to see your results</p>
              </motion.div>
            ) : selectedExamSet ? (
              <ResultsDetail key="details" examSet={selectedExamSet} />
            ) : (
              <ResultsList key="list" />
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Results;