import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiPlus, FiEdit2, FiTrash, FiClock, FiUsers } from "react-icons/fi";
import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";
import { addHour, updateHour, deleteHour, updateHourStudents } from '../../../redux/actions/admin/hour';
import { getStudentGroups } from "../../../redux/actions/admin/studentgroup";
import { getTimetables } from '../../../redux/actions/admin/timetable';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { getEnrollments } from '../../../redux/actions/admin/enrollment';
import { getUsers } from '../../../redux/actions/admin/user';

const TimeTableSetup = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { setTitle } = props;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isStudentDialogOpen, setIsStudentDialogOpen] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedPeriodIndex, setSelectedPeriodIndex] = useState(null);
    const [formData, setFormData] = useState({
        from: '',
        to: '',
        studentgroupId: '',
        timetableId: '',
        day: ''
    });
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [studentList, setStudentList] = useState([]);

    const studentGroups = useSelector((state) => state?.studentgroup?.value?.data);
    const enrollments = useSelector((enrollments) => enrollments.enrollment.value.data);
    const users = useSelector((users) => users.user.value.data);

    const [timetableData, setTimetableData] = useState([
        { day: "Monday", periods: [] },
        { day: "Tuesday", periods: [] },
        { day: "Wednesday", periods: [] },
        { day: "Thursday", periods: [] },
        { day: "Friday", periods: [] },
        { day: "Saturday", periods: [] },
        { day: "Sunday", periods: [] }
    ]);

    const extractTimeFromISOString = (isoString) => {
        if (!isoString) return '';
        return isoString.slice(11, 16); // Extract HH:mm from the ISO string
    };

    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    });

    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    });

    useEffect(() => {
        dispatch(getTimetables({ id })).then((data) => {
            if (data?.payload?.rows?.[0]?.hours) {
                updateTimetableDataFromHours(data.payload.rows[0].hours);
                setTitle(data?.payload?.rows[0]?.class?.name + " - Time Table");
            }
        });
        dispatch(getStudentGroups({ limit: 100 }));
        dispatch(getEnrollments({ limit: 300 }));
        dispatch(getUsers({
                    role: 'Staff',
                    limit: 50
                }));
    }, [dispatch, id]);

    const refresh = () => {
        dispatch(getTimetables({ id })).then((data) => {
            if (data?.payload?.rows?.[0]?.hours) {
                updateTimetableDataFromHours(data.payload.rows[0].hours);
            }
        });
    }

    const updateTimetableDataFromHours = (hours) => {
        const newTimetableData = [
            { day: "Monday", periods: [] },
            { day: "Tuesday", periods: [] },
            { day: "Wednesday", periods: [] },
            { day: "Thursday", periods: [] },
            { day: "Friday", periods: [] },
            { day: "Saturday", periods: [] },
            { day: "Sunday", periods: [] }
        ];

        const sortedHours = [...hours].sort((a, b) => {
            return new Date('1970/01/01 ' + extractTimeFromISOString(a.from)) - new Date('1970/01/01 ' + extractTimeFromISOString(b.from));
        });

        sortedHours.forEach(hour => {
            const dayData = newTimetableData.find(d => d.day === hour.day);
            if (dayData) {
                dayData.periods.push({
                    id: hour.id,
                    from: hour.from,
                    to: hour.to,
                    studentgroupId: hour.studentgroupId,
                    hourstudents: hour.hourstudents,
                    user: hour.user,
                    userId: hour.userId,
                    displayFrom: extractTimeFromISOString(hour.from),
                    displayTo: extractTimeFromISOString(hour.to)
                });
            }
        });

        setTimetableData(newTimetableData);
    };

    const handleAddPeriod = (day) => {
        setSelectedDay(day);
        setSelectedPeriodIndex(null);
        setFormData({
            from: '',
            to: '',
            studentgroupId: '',
            timetableId: id,
            day: day
        });
        setIsDialogOpen(true);
    };

    const handleEditPeriod = (day, periodIndex, periodData, e) => {
        e.stopPropagation();
        setSelectedDay(day);
        setSelectedPeriodIndex(periodIndex);
        setFormData({
            id: periodData.id,
            studentgroup: periodData.studentgroup,
            from: extractTimeFromISOString(periodData.from),
            to: extractTimeFromISOString(periodData.to),
            studentgroupId: periodData.studentgroupId,
        });
        setIsDialogOpen(true);
    };

    const handleDeletePrompt = (day, periodIndex, e) => {
        e.stopPropagation();
        setSelectedDay(day);
        setSelectedPeriodIndex(periodIndex);
        setIsDeleteDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const dayData = timetableData.find(item => item.day === selectedDay);
            const periodToDelete = dayData.periods[selectedPeriodIndex];

            const result = await dispatch(deleteHour(periodToDelete.id));

            if (result.payload) {
                notifySuccess('Period deleted successfully');
                refresh()
            } else {
                notifyError('Failed to delete period');
            }

            setIsDeleteDialogOpen(false);
        } catch (error) {
            notifyError('Error deleting period: ' + error.message);
        }
    };

    const handleSavePeriod = async () => {
        try {
            const today = new Date().toISOString().split('T')[0];
            const payload = {
                ...formData,
                timetableId: id,
                from: `${today}T${formData.from}:00.000Z`,
                to: `${today}T${formData.to}:00.000Z`
            };

            let result;
            if (selectedPeriodIndex !== null) {
                result = await dispatch(updateHour(payload));
            } else {
                result = await dispatch(addHour(payload));
            }

            if (result.payload.success) {
                notifySuccess(`Period ${selectedPeriodIndex !== null ? 'updated' : 'added'} successfully`);
                refresh()

                setIsDialogOpen(false);
                setFormData({
                    from: '',
                    to: '',
                    studentgroupId: '',
                    timetableId: '',
                    day: ''
                });
            } else {
                notifyError(`Failed to ${selectedPeriodIndex !== null ? 'update' : 'add'} period`);
            }
        } catch (error) {
            notifyError(`Error ${selectedPeriodIndex !== null ? 'updating' : 'adding'} period: ` + error.message);
        }
    };

    const handleManageStudents = (day, periodIndex, periodData, e) => {
        e.stopPropagation();
        setSelectedDay(day);
        setSelectedPeriodIndex(periodIndex);
        setSelectedPeriod(periodData);
        setStudentList(periodData.hourstudents || []);
        setIsStudentDialogOpen(true);
    };

    const handleAddStudent = (studentId) => {
        const updatedStudents = [...studentList, { id: studentId }];
        setStudentList(updatedStudents);
        updatePeriodStudents(updatedStudents);
    };

    const handleRemoveStudent = (studentId) => {
        const updatedStudents = studentList.filter(student => student.id !== studentId);
        setStudentList(updatedStudents);
        updatePeriodStudents(updatedStudents);
    };

    const updatePeriodStudents = async (updatedStudents) => {
        try {
            const payload = {
                ...selectedPeriod,
                hourstudents: updatedStudents
            };

            const result = await dispatch(updateHourStudents(payload));

            if (result.payload.success) {
                notifySuccess('Students updated successfully');
                // setIsStudentDialogOpen(false);
                refresh();
            } else {
                notifyError('Failed to update students');
            }
        } catch (error) {
            notifyError('Error updating students: ' + error.message);
        }
    };

    const fields = [
        {
            id: "from",
            name: "from",
            label: "From",
            placeholder: "Select time",
            type: "time",
            value: formData.from
        },
        {
            id: "to",
            name: "to",
            label: "To",
            placeholder: "Select time",
            type: "time",
            value: formData.to
        },
        {
            id: "userId",
            name: "userId",
            label: "Staff",
            placeHolder: users.rows?.length ? "Select Staff" : "No staffs available",
            type: "dropdown",
            list: users.rows,
            toSelect: "id",
            selectedvalue: "user",
            toShow: ['fullname'],
            required: true,
            disabled: !users.rows?.length
        },
    ];

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <div className="h-full w-full rounded-xl">
                <div className="mb-6 flex justify-between items-center">
                    <h2 className="text-2xl font-bold">Timetable Setup</h2>
                </div>
                <Toaster />
                <div className="space-y-6">
                    {timetableData.map((dayData) => (
                        <div key={dayData.day} className="p-6 rounded-xl border-2 border-gray-100">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-xl font-semibold">{dayData.day}</h3>
                                <button
                                    onClick={() => handleAddPeriod(dayData.day)}
                                    className="flex items-center gap-2 bg-brand-700 text-white px-4 py-2 rounded-full hover:bg-brand-850/80 transition-colors"
                                >
                                    <FiPlus className="h-4 w-4" />
                                    Add Period
                                </button>
                            </div>

                            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                                {dayData.periods.map((period, index) => (
                                    <div
                                        key={index}
                                        className="group relative bg-gray-50 p-4 rounded-xl border-2 border-gray-100 hover:border-brand-700 transition-all"
                                    >
                                        <div className="absolute top-2 right-2 flex gap-2">
                                            <FiEdit2
                                                onClick={(e) => handleEditPeriod(dayData.day, index, period, e)}
                                                className="h-4 w-4 text-gray-400 hover:text-brand-700 cursor-pointer"
                                            />
                                            <FiTrash
                                                onClick={(e) => handleDeletePrompt(dayData.day, index, e)}
                                                className="h-4 w-4 text-gray-400 hover:text-red-500 cursor-pointer"
                                            />
                                            <FiUsers
                                                onClick={(e) => handleManageStudents(dayData.day, index, period, e)}
                                                className="h-4 w-4 text-gray-400 hover:text-brand-700 cursor-pointer"
                                            />
                                        </div>
                                        <div className="flex flex-col gap-2">
                                            <div className="flex items-center gap-2 text-brand-700">
                                                <FiClock className="h-4 w-4" />
                                                <span className="font-medium">Period {index + 1}</span>
                                            </div>
                                            <div className="text-sm text-gray-600">
                                                {period?.hourstudents?.length || "0"} Students
                                            </div>
                                            <div className="text-sm text-gray-600">
                                                {period?.user?.fullname || "NA"} 
                                            </div>
                                            <div className="text-sm text-gray-600">
                                                {period.displayFrom} - {period.displayTo}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {dayData.periods.length === 0 && (
                                <div className="text-center py-8 text-gray-500">
                                    No periods added yet
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <DialogCustom
                    open={isDialogOpen}
                    onOpenChange={setIsDialogOpen}
                    dialogTitle={`${selectedPeriodIndex !== null ? 'Update' : 'Add'} Period for ${selectedDay}`}
                    dialogWidth="w-1/2"
                    dialogDesc={`${selectedPeriodIndex !== null ? 'Update' : 'Add'} the period details and save.`}
                    content={
                        <>
                            <Form
                                fields={fields}
                                formData={formData}
                                onChange={(newFormData) => setFormData(newFormData)}
                            />
                            <div className='flex justify-end'>
                                <button
                                    onClick={handleSavePeriod}
                                    className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white active:!bg-white/70"
                                >
                                    {selectedPeriodIndex !== null ? 'Update' : 'Add'} Period
                                </button>
                            </div>
                        </>
                    }
                />

                <DialogCustom
                    open={isDeleteDialogOpen}
                    onOpenChange={setIsDeleteDialogOpen}
                    dialogTitle="Delete Period"
                    dialogWidth="w-1/2"
                    dialogDesc="Are you sure you want to delete this period?"
                    content={
                        <div className='flex flex-row-reverse'>
                            <button
                                onClick={handleDelete}
                                className="text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg"
                            >
                                Delete
                            </button>
                            <button
                                onClick={() => setIsDeleteDialogOpen(false)}
                                className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg"
                            >
                                Cancel
                            </button>
                        </div>
                    }
                />

                <DialogCustom
                    open={isStudentDialogOpen}
                    onOpenChange={setIsStudentDialogOpen}
                    dialogTitle={`Manage Students for Period ${selectedPeriodIndex + 1} on ${selectedDay}`}
                    dialogWidth="w-1/2"
                    dialogDesc="Add or remove students from this period."
                    content={
                        <>
                            <div className="mb-4">
                                <h4 className="text-lg font-semibold">Current Students</h4>
                                <ul>
                                    {studentList.map((student, index) => (
                                        <li key={index} className="flex justify-between items-center mb-2 py-2 border-2 border-gray-500 rounded-lg">
                                            <span className='pl-2'>{student?.enrollment?.student?.admission?.name || `Student ${student.id}`}</span>
                                            <button
                                                onClick={() => handleRemoveStudent(student.id)}
                                                className="bg-red-500 rounded-lg text-white p-2 mr-2 "
                                            >
                                                Remove
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <h4 className="text-lg font-semibold">Add Student</h4>
                                <select
                                    onChange={(e) => handleAddStudent(e.target.value)}
                                    className="mt-2 p-2 border rounded-md w-full"
                                >
                                    <option value="">Select a student</option>
                                    {enrollments?.rows?.map((enrollment) => (
                                        <option key={enrollment.id} value={enrollment.id}>
                                            {enrollment?.student?.admission?.name + ' - ' + enrollment?.class?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </>
                    }
                />
            </div>
        </div>
    );
};

export default TimeTableSetup;
