import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateFCMtoken } from "../src/redux/actions/auth";
import { initializeApp } from "firebase/app";
import { onBackgroundMessage } from "firebase/messaging/sw";
import { getMessaging, getToken, onMessage, setBackgroundMessageHandler } from "firebase/messaging";
import { motion,  AnimatePresence } from 'framer-motion';
import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import ParentLayout from "layouts/parent";
import OpenLayout from "layouts/open";
import AuthLayout from "layouts/auth";
import { validateToken } from './redux/actions/auth';

const isWebView = () => {
  // Detect if the app is running inside a WebView
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /wv|WebView/.test(userAgent) || window.ReactNativeWebView !== undefined;
};

const App = () => {
  const [fcmToken, setFcmToken] = React.useState("")
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = React.useState(true);
  

  React.useEffect(() => {
    dispatch( updateFCMtoken({fcmToken}) )
  }, [fcmToken])


  const firebaseConfig = {
    apiKey: "AIzaSyB_Rzw0PXLANxj5Azm86dIBtCQdSzDTOdA",
    authDomain: "maktab-c2f56.firebaseapp.com",
    projectId: "maktab-c2f56",
    storageBucket: "maktab-c2f56.appspot.com",
    messagingSenderId: "490973311187",
    appId: "1:490973311187:web:ee237ba6951632a4242426",
    measurementId: "G-9ZSZCYQH85"
  };


  const app = initializeApp(firebaseConfig);

  const messaging = getMessaging();

  onMessage(messaging, (payload) => {
    if(payload) {
      navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
        registration.showNotification(
            payload.notification.title,
            payload.notification
        )
    });
      console.log('Message received. ', payload);
    } else {
      console.log('nothing received');
    }
    // ...
  });

  
  function requestPermission() {
    console.log('Requesting permission...');
    try {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
      }
    })
    } catch( err ) {
      console.log(err)
    }
  }
  requestPermission()

  React.useEffect(() => {
    const initializeApp = async () => {
      try {
        await requestPermission();
        
        if (isWebView()) {
          console.log('Running in WebView');
          window.registerDevice = async (token) => {
            setFcmToken(token);
            setIsLoading(false);
          };
        } else {
          console.log('Running in Browser');
          const currentToken = await getToken(messaging, {
            vapidKey: 'BI5rCpt0KwlhX-xu8virZgWN9ZT6f9Z30xpykmlL-z6XZuuJAT9x4hwxt0MDYzyCt8L5Lk3-p3zuQfmCMqyN8Us',
          });
          
          if (currentToken) {
            setFcmToken(currentToken);
          }
        }
      } catch (error) {
        console.error('Initialization error:', error);
      } finally {
        // Ensure loading screen shows for at least 1 second for smooth transition
        setTimeout(() => setIsLoading(false), 1000);
      }
    };

    initializeApp();
  }, []);

  // getToken(messaging, { vapidKey: 'BI5rCpt0KwlhX-xu8virZgWN9ZT6f9Z30xpykmlL-z6XZuuJAT9x4hwxt0MDYzyCt8L5Lk3-p3zuQfmCMqyN8Us' }).then((currentToken) => {
  //   if (currentToken) {
  //     // Send the token to your server and update the UI if necessary
  //     // ...
  //     setFcmToken(currentToken)
  //     // fcmToken = currentToken
  //     console.log(currentToken, "currentTokencurrentTokencurrentToken");
  //   } else {
  //     // Show permission request UI
  //     console.log('No registration token available. Request permission to generate one.');
  //     // ...
  //   }
  // }).catch((err) => {
  //   console.log('An error occurred while retrieving token. ', err);
  //   // ...
  // });

  // React.useEffect(() => {
  //   onLoggedIn()
  //   // We expose a method to register a Device
  //   window.registerDevice = async (token) => {
  //     alert("registerDevice --> ", token.length)
  //     setFcmToken(token)
  //   }
  // }, [])

  if ('serviceWorker' in navigator) {
    console.log('Service Worker registering:');
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
            .then(registration => {
                console.log('Service Worker registered:', registration);
            })
            .catch(error => {
                console.error('Service Worker registration failed:', error);
            });
    });   
}

const LoadingScreen = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 flex items-center justify-center bg-white dark:bg-gray-900"
    >
      <div className="flex flex-col items-center gap-4">
      <img
          src="https://maktab.info/wp-content/uploads/2024/12/maktab-logo.png"
          className="mb-3 rounded-xl w-60"
          alt=""
        />
        <motion.div 
          className="w-12 h-12 rounded-full border-4 border-gray-300 border-t-brand-500"
          animate={{ 
            rotate: 360 
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
            ease: "linear"
          }}
        />
        <motion.p
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-gray-600 dark:text-gray-300 text-sm font-medium"
        >
          Loading...
        </motion.p>
      </div>
    </motion.div>
  );
};

return (
  <>
    <AnimatePresence mode="wait">
      {isLoading && <LoadingScreen />}
    </AnimatePresence>

    {!isLoading && (
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="parent/*" element={<ParentLayout />} />
        <Route path="open/*" element={<OpenLayout />} />
        <Route path="rtl/*" element={<RtlLayout />} />
        <Route path="/" element={<Navigate to="/admin" replace />} />
      </Routes>
    )}
  </>
);
};

export default App;
